import DefaultDataTable from "../utils/DefaultDataTable";

export default class DrivingTestsIndex {

    constructor() {
        this.bindEvents();
        //window.application.setOnDataChangeListener(this);

        this.selectedDrivingTest = null;
        this.timeoutID = null;
    }


    bindEvents() {
      let self = this;
      $(".add-candidate-btn").off("click").on("click", function(e) {
        e.preventDefault();

        self.selectedDrivingTest = $(this).data("drivingTestId");

        var tbody = $("#candidate_search_table").find("tbody")
        tbody.html("");

        $("#add_candidate_btn").attr("disabled", true);

        $("#search_query").val("");
        $("#select_candidate_modal").modal();
         self.search();
      });

      $(".edit-comment-btn").off("click").on("click", function(e) {
        e.preventDefault();

        var actionURL = $(this).data("action_url");

        $("#comment_candidate_form").attr("action", actionURL);
        $("#driving_test_candidate_comment").val($(this).data("content"));
        $("#comment_candidate_modal").modal();
      });

      $("#update_candidate_comment_btn").off("click").on("click", function(e) {
        e.preventDefault();
        $("#comment_candidate_form").submit();
      });

      $(".cancel-candidate-btn").off("click").on("click", function(e) {
        e.preventDefault();

        $("#cancellation-reason-btn-group > label").on("click", function(e) {
          $("#cancellation-reason-btn-group > label").removeClass("active").removeClass("btn-primary").addClass("btn-secondary");
          $(this).addClass("active").addClass("btn-primary").removeClass("btn-secondary");
        });

        var actionURL = $(this).data("action_url");
        $("#cancel_candidate_full_name").html($(this).data("full_name"));
        $("#cancel_candidate_form").attr("action", actionURL);
        $("#cancel_candidate_modal").modal();
      });


      $("#select_candidate_form").find("input[type=radio]").off("change").on("change", function(e) { 
        e.preventDefault();

        $("#candidate_search_table").find("tbody").html("");

        if( $(this).val() == "postponed") {
          $("#cancellation_reason_header").removeClass("d-none");
        }
        else {
          $("#cancellation_reason_header").addClass("d-none");
        }

        self.search();
      })


      $("#search_query").off("keyup").on("keyup", function(e) {
        e.preventDefault();

        self.search();
      });


      $("#select_candidate_form").off("submit").on("submit", function(e) {
        e.preventDefault();

        self.search();
      });

      $("#add_candidate_btn").off("click").on("click", function(e) {
        e.preventDefault();

        self.addSelectedCandidates();
      });


      $("#city_test_id").change(function (){
        window.location.href = "/driving_tests?date="+$("#city_test_id > option:selected").data("date")+"&city_id="+$(this).val();
      });

      $('.open-candidate-details-modal').off("click").on('click', function (e) {
        let loadurl = $(this).data("href");
        let $modal = $("#show_candidate_details_modal");
        $modal.modal();
        $modal.find('.modal-content').html("");
        $modal.find('.modal-content').load(loadurl);
      });

    }

    search() {
      let self = this;
      if(this.timeoutID!=null) {
        clearTimeout(this.timeoutID);
      }
      this.timeoutID = setTimeout(function() {
        $("#add_candidate_btn").attr("disabled", true);
        $("#candidate_search_table").find("tbody").html("");
        let action = $("#select_candidate_form").attr("action");

        let data = $("#select_candidate_form").serializeJSON()
        data.driving_test_id = self.selectedDrivingTest;

        let ajaxData = {
          url: action,
          data : data,
          method: "GET"
        };

        $.ajax(ajaxData).done(function(json) {
          console.log("search response")
          console.log(json);
          self.displayResults(json.results);
        });
      }, 250);
      
    }

    addSelectedCandidates() {
      let self = this;
      $("#add_candidate_btn").attr("disabled", true);

      let ajaxData = {
          url: "/driving_tests/"+this.selectedDrivingTest+"/candidates",
          data : $("#candidate_result_table_form").serializeJSON(),
          method: "POST"
        };

        $.ajax(ajaxData).done(function(json) {});

        Turbolinks.scroll['top'] = document.scrollingElement.scrollTop;
    }

    displayResults(results) {

      var tbody = $("#candidate_search_table").find("tbody")
      tbody.html("");

      for(var i = 0; i<results.length;i++) {
        var item = results[i];
        var row = $("<tr>");

        if(item.highway_code_will_be_expired) {
          row.addClass("bg-warning");
        }

        var fCol =$("<td>");

        $("<input>").attr({ name: "selected_candidate_ids[]", type: "checkbox", value: item.id }).addClass("selected_candidate_checkbox").appendTo(fCol);

        fCol.appendTo(row);

        if(item.current_record!=null) {
          $("<td>").html(item.last_name).appendTo(row);
          $("<td>").html(item.first_name).appendTo(row);
          $("<td>").html(item.current_record.formula).appendTo(row);
          $("<td>").html(I18n.t("activerecord.attributes.customer.driving_test_priorities."+item.driving_test_priority)).appendTo(row);
          //$("<td>").html(I18n.t("activerecord.attributes.driving_instructor.driving_transmission_types_alt."+item.current_record.driving_transmission_type)).appendTo(row);
          $("<td>").html(item.current_record.ends_at).appendTo(row);
          $("<td>").html(item.highway_code_expires_at!=null ? item.highway_code_expires_at : "?").appendTo(row);
          if(item.cancellation_reason_html != null) {
            $("<td>").html(item.cancellation_reason_html).appendTo(row);
          }
        }
        else {
          $("<td>").html(item.last_name).appendTo(row);
          $("<td>").html(item.first_name).appendTo(row);
          $("<td>").html("-").appendTo(row);
          $("<td>").html(I18n.t("activerecord.attributes.customer.driving_test_priorities."+item.driving_test_priority)).appendTo(row);
          //$("<td>").html("-").appendTo(row);
          $("<td>").html("-").appendTo(row);
          $("<td>").html(item.highway_code_expires_at!=null ? item.highway_code_expires_at : "?").appendTo(row);
        }

        row.appendTo(tbody);
      }


      $(".selected_candidate_checkbox").off("change").on("change", function(e) {
        e.preventDefault();

        var formData = $("#candidate_result_table_form").serializeJSON();
        if(formData.selected_candidate_ids==null || formData.selected_candidate_ids.length==0) {
          $("#add_candidate_btn").attr("disabled", true);
        }
        else {
          $("#add_candidate_btn").attr("disabled", false);
        }

      });

    }

    onDataChanged(data) {
    
    }
}
import { Calendar } from '@fullcalendar/core';
import frenchTranslation from '@fullcalendar/core/locales/fr';
import bootstrapPlugin from '@fullcalendar/bootstrap';
import interactionPlugin from '@fullcalendar/interaction';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import scrollgridPlugin from '@fullcalendar/scrollgrid';

import resourceTimeGrid from '@fullcalendar/resource-timegrid';
import resourceTimeline from '@fullcalendar/resource-timeline';

import CalendarEntry from "../utils/CalendarEntry";


export default class PlanningIndex {
    constructor() {

        var self = this;
        this.selectedEvent = null;
        this.selectedDrivingInstructorId = $("#selected_driving_instructor_id").val();

        this.selectedDrivingInstructorPage = $("#selected_driving_instructor_page").val();
        if(this.selectedDrivingInstructorPage==null) {
          this.selectedDrivingInstructorPage = 1;
        }

        this.currentDrivingInstructorId = null;

        this.entity_id = null;

        this.city_id = $("#city_id").val();
        this.instructor_type = $("#instructor_type").val();

        this.driving_transmission_type = null;
        
        this.driving_instructors = [];

        this.base_url = null;

        $("#entity_selection").select2({
          dropdownParent: $("#calendar_card"),
          dropdownPosition: 'below'
        });

        $("#selected_driving_instructor_id").select2({
          dropdownParent: $("#calendar_card"),
          dropdownPosition: 'below'
        });
       

        $("#current_date").unbind("change").on("change", function(e) {
            if(moment($(this).val()).isValid()) {
              self.calendar.gotoDate($(this).val());
            }
          
        });

        if($("#planning_calendar").length >0) {

          this.entity_id = $("#planning_calendar").data("entity_id")

          if(window.application.context=="bo") {
            this.base_url = "/planning/"+this.entity_id;
          }
          else {
            this.base_url = "/planning/";
          }

          
          this.business_hours = $("#planning_calendar").data("working_hours_schedule");
          this.initial_date = $("#planning_calendar").data("initial_date");
          this.driving_transmission_type = $("#planning_calendar").data("driving_transmission_type");

          let leftView = "prev,prev_day next_day,next today fullscreen";
          let initialView = "resourceTimeGridDay";
          let otherViews = "resourceTimeGridDay,resourceTimeline,listWeek";
          this.timeGridWeekConfig = { buttonText: "Semaine", days: 7, dateAlignment: "week" }
          if(this.selectedDrivingInstructorId!="") {
            leftView = "prev,prev_day next_day,next today fullscreen";
            initialView = "timeGridWeek";
            otherViews = "timeGridWeek,listWeek";
            this.timeGridWeekConfig = { buttonText: "Semaine", days: 7, dateAlignment: "day" };
          }

          this.calendar = new Calendar(document.getElementById('planning_calendar'), {
            schedulerLicenseKey: 'CC-Attribution-NonCommercial-NoDerivatives',
            themeSystem: "bootstrap",
            locale: frenchTranslation,
            initialView: initialView,
            initialDate: (this.initial_date==null ? new Date() : this.initial_date),
            slotDuration: "00:30:00",
            slotMinTime: "06:00:00",
            slotMaxTime: "21:00:00",
            dayMaxEvents: true,
            dayMinWidth: 250,
            allDaySlot: false,
            editable: false,
            eventStartEditable: false,
            eventResourceEditable: true,
            selectable: true,
            height: 800,
            //timeZone: "Europe/Paris",
            plugins: [bootstrapPlugin, interactionPlugin, scrollgridPlugin, dayGridPlugin, timeGridPlugin, listPlugin, resourceTimeGrid, resourceTimeline ],
            headerToolbar: { 
              left: leftView, 
              center: "title", 
              right: otherViews
            },
            bootstrapFontAwesome: {
              fullscreen: "fa-expand-arrows-alt",
              prev: "fa-backward",
              next: "fa-forward",
              prev_day: "fa-chevron-left",
              next_day: "fa-chevron-right",
            },
            customButtons: {
              prev: {
                click: function() {
                  var startD = self.calendar.currentDataManager.state.dateProfile.currentRange.start;
                  var endD = self.calendar.currentDataManager.state.dateProfile.currentRange.end;

                  startD.setHours(0);
                  endD.setHours(0);

                  self.calendar.setOption('visibleRange', {
                    start: startD.addDays(-7),
                    end: endD.addDays(-7)
                  });
                  self.calendar.incrementDate({ days: -7, hours: 0 });

                  $("#current_date").val(moment(startD.addDays(-7)).format("y-MM-DD"));
                }
              },
              next: {
                click: function() {
                  var startD = self.calendar.currentDataManager.state.dateProfile.currentRange.start;
                  var endD = self.calendar.currentDataManager.state.dateProfile.currentRange.end;

                  startD.setHours(0);
                  endD.setHours(0);

                  self.calendar.setOption('visibleRange', {
                    start: startD.addDays(7),
                    end: endD.addDays(7)
                  });
                  self.calendar.incrementDate({ days: 7, hours: 0 });

                  $("#current_date").val(moment(startD.addDays(7)).format("y-MM-DD"));
                }
              },
              prev_day: {
                click: function() {
                  var startD = self.calendar.currentDataManager.state.dateProfile.currentRange.start;
                  var endD = self.calendar.currentDataManager.state.dateProfile.currentRange.end;

                  startD.setHours(0);
                  endD.setHours(0);

                  self.calendar.incrementDate({ days: -1, hours: 0 });
                  self.calendar.setOption('visibleRange', {
                    start: startD.addDays(-1),
                    end: endD.addDays(-1)
                  });
                  // self.calendar.incrementDate(-24*3600*1000)

                  $("#current_date").val(moment(startD.addDays(-1)).format("y-MM-DD"));
                }
              },
              next_day: {
                click: function() {
                  //self.calendar.incrementDate("24:00")

                  var startD = self.calendar.currentDataManager.state.dateProfile.currentRange.start;
                  var endD = self.calendar.currentDataManager.state.dateProfile.currentRange.end;

                  startD.setHours(0);
                  endD.setHours(0);

                  self.calendar.incrementDate({ days: 1, hours: 0 });
                  self.calendar.setOption('visibleRange', {
                    start: startD.addDays(1),
                    end: endD.addDays(1)
                  });
                  // self.calendar.incrementDate(24*3600*1000)
                  

                  $("#current_date").val(moment(startD.addDays(1)).format("y-MM-DD"));
                }
              },
              fullscreen: {

                click: function(){
                  let elem = document.getElementById('calendar_card');
                  if(elem.webkitRequestFullScreen!=undefined) {
                    if(document.webkitFullscreenElement) {
                      document.webkitCancelFullScreen();
                    }
                    else {
                      elem.webkitRequestFullScreen();
                    }
                  }
                  else {
                    if(document.fullscreenElement) {
                      document.exitFullscreen();
                    }
                    else {
                      elem.requestFullscreen();
                    }
                  }
                }
              }
            },
            views: {
              timeGridWeek: self.timeGridWeekConfig,
              resourceTimeline: {
                buttonText: "Semaine",
                duration: { days: 7 },
                slotMinWidth: 200,
                resourceAreaWidth: "15%"
              },
              resourceTimeGridDay: {
                type: 'resourceTimeGridDay',
                duration: { days: 1 },
                buttonText: 'Jour',
                slotMinWidth: 200,
                resourceAreaWidth: "15%"
              }
            },
            businessHours: self.business_hours,
            events: {
              url: self.base_url+'/events',
              method: 'GET',
              extraParams: {
                city_id: self.city_id!=undefined ? self.city_id : "",
                instructor_type: self.instructor_type!=undefined ? self.instructor_type : "",
                instructor_id: self.selectedDrivingInstructorId
              },
              failure: function() {
                console.log('there was an error while fetching events!');
              },
              //color: 'yellow',   // a non-ajax option
              textColor: 'black' // a non-ajax option
            },
            eventSourceSuccess: function(content, xhr) {
              console.log("eventSourceSuccess -> "+content.events.length);
              let events = content.events;
              let holidays = content.holidays;
              self.delayedBindAdditionalCalendarFeatures();

              if(self.selectedDrivingInstructorId==null) {
                 return events;
              }
              else {

                /*for(var i=0;i<events.length;i++) {
                  events[i]["startEditable"] = true;
                }*/

                return events.concat(holidays);
              }

                
            },
            resourceOrder: 'position',
            resources: {
              url: self.base_url+'/driving_instructors',
              method: 'GET',
              extraParams: {
                page: self.selectedDrivingInstructorPage,
                city_id: self.city_id!=undefined ? self.city_id : "",
                instructor_type: self.instructor_type!=undefined ? self.instructor_type : ""
              },
              success: function(raw_resources, xhr) {

              }
            },
            dateClick: function(arg) { self.onDateClicked(arg) },
            eventClick: function(info) { self.onEventClicked(info) },
            loading: function(isLoading) {      
              //console.log("isLoading -> "+isLoading);
            },
            viewDidMount: function(arg) {
              self.delayedBindAdditionalCalendarFeatures();
            },
            eventContent: function(arg) {
              /*console.log("eventContent called");
              let title = document.createElement("div")
              let customerName = document.createElement("div")
              let icon = document.createElement('i')

              icon.className = 'ri-user-fill';
              title.appendChild(icon);

              if (arg.event.extendedProps.customerIds.length > 1) {
                let icon2 = document.createElement('i')
                icon2.className = 'ri-user-fill';
                title.appendChild(icon2);
              }

              title.innerHTML += " "+arg.event.title;

              customerName.innerHTML = self.getEventCustomerNames(arg.event).join(" et ");

              let arrayOfDomNodes = [ title, customerName ]
              return { domNodes: arrayOfDomNodes }
              */
              if(arg.event.display!=null && arg.event.display=="background") {
                return {};
              }
              else {
                var entry = new CalendarEntry(arg);
                
                return entry.render().getRootNode();
              }

              
            },
            eventDrop(dropInfo) {



              console.log(dropInfo);

              var start = moment(dropInfo.event.start);
              var end = moment(dropInfo.event.end);

              var duration = moment.duration(end.diff(start));
              var hours = duration.hours();
              var minutes = duration.minutes();

              var changes = {
                date: start.toDate().toISOString(),
                duration : self.pad(hours,2)+":"+self.pad(minutes,2)
              }

              if(dropInfo.oldEvent._def.resourceIds.length>0 && dropInfo.event._def.resourceIds.length>0 && dropInfo.oldEvent._def.resourceIds[0]!=dropInfo.event._def.resourceIds[0]) {
                changes["driving_instructor_id"] =  dropInfo.event._def.resourceIds[0];
              }

              self.updateEvent(dropInfo.event.id, {
                is_drop: true,
                calendar_event: changes
              });
            }
          });

          this.calendar.render();

          this.checkTimezone();
        }

        this.bindEvents();
    }

    checkTimezone() {
      var timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      if(timezone!="Europe/Paris") {
        $("#wrong-timezone-warning").removeClass("d-none");
        $("#wrong-timezone-warning-name").html(timezone);
      }
    }

    onDateClicked(arg) {
      var self = this;
      //if(self.selectedDrivingInstructorId!=null) {
        this.openNewEventModal(arg.date, arg.resource);
      //}
    }

    onEventClicked(e) {
      var self = this;
      //console.log(e.event._def.resourceIds[0]);

      if(self.selectedDrivingInstructorId==null || self.selectedDrivingInstructorId=="") {
        self.currentDrivingInstructorId = e.event._def.resourceIds[0];
      }
      else {
        self.currentDrivingInstructorId = self.selectedDrivingInstructorId;
      }
      

      if(self.currentDrivingInstructorId!=null) {
        var event = e.event;
        this.selectedEvent = event;
        e.jsEvent.preventDefault();

        PrettyAlert.clear("#edit_event_form");

        $("#edit_event_form").unbind("submit").submit(function(e) {
          e.preventDefault();

          self.updateEvent(event.id, $(this).serializeJSON());

        });

        if(event.extendedProps.is_deletable) {

          $("#delete_event_btn").removeClass("d-none").unbind("click").click(function(e) {
            e.preventDefault();

            $("#edit_event_modal").modal("hide");
            self.deleteEvent(event.id);
          });
        }
        else {
          $("#delete_event_btn").addClass("d-none");
        }

        if(event.extendedProps.event_type=="type_driving_lesson" && event.extendedProps.affectedSessionIds.length>0) { //|| event.extendedProps.event_type=="type_driving_lesson_duo") {
          $("#notify_update_planning_btn").removeClass("d-none").off("click").on("click", function(e) {
            self.notifyPlanningUpdate(event.id);
          });
          $("#notified_planning_manually_btn").removeClass("d-none").off("click").on("click", function(e) {
            self.notifiedPlanningManually(event.id);
          });
        }
        else if(event.extendedProps.event_type=="type_pack_hour") {
          $("#notify_update_planning_btn").removeClass("d-none").off("click").on("click", function(e) {
            self.notifyPlanningUpdate(event.id);
          });
        }
        else {
           $("#notify_update_planning_btn").addClass("d-none");
        }

        if(event.extendedProps.event_type=="type_driving_test") {
          $(".edit-event-driving-test-part").removeClass("d-none");
        }
        else {
          $(".edit-event-driving-test-part").addClass("d-none");
        }

        $("#edit_event_modal").modal();
        $('.modal-backdrop').appendTo("#calendar_card");

        if(self.currentDrivingInstructorId==null || self.currentDrivingInstructorId=="") {
          $("#edit_event_driving_instructor_id").html("");

          $.each(this.calendar.currentData.resourceStore, function(idx, resource) {
            $("<option>").attr("value", resource.id).html(resource.title).appendTo("#edit_event_driving_instructor_id");
          });
        }
        else {
           $("<option>").attr("value", self.currentDrivingInstructorId).html("").appendTo("#edit_event_driving_instructor_id");
        }

        $("#edit_event_place_indication").val(event.extendedProps.place_indication);
        $("#edit_event_description").val(event.extendedProps.description);
        $("#edit_driving_test_description").val(event.extendedProps.driving_test_description);
        $("#edit_driving_test_description_hr").html(event.extendedProps.driving_test_description);

        $("#edit_event_free_explanation").val(event.extendedProps.free_explanation==null ? "" : event.extendedProps.free_explanation);

        $("#edit_event_driving_instructor_id").val(event._def.resourceIds[0]).trigger("change");

        $("#edit_event_customer_ids").val(null).trigger("change");
        $("#edit_event_customer_ids").find("option").remove();

        $("#edit-event-customer-informations").html("");
        $.each(event.extendedProps.customers, function(idx, customer){
          var option = new Option(customer.text, customer.id, true, true);
          $("#edit_event_customer_ids").append(option);
          var customerCol = $("<div>").addClass("col mb-4");
          customerCol.html("<u><a target='_blank' class='font-weight-bold' href='/clients/" + customer.id + "/edit'>"+customer.additional_informations.name+"</a></u><br/><a href=\"tel:"+customer.additional_informations.phone+"\"><i class=\"mdi mdi-phone\"></i>"+customer.additional_informations.phone+"</a>")
          customerCol.appendTo("#edit-event-customer-informations");
        });

        console.log(event.extendedProps.driving_transmission_type);
        if(event.extendedProps.driving_transmission_type!=null) {
          $("#edit_event_driving_transmission_type").val("driving_transmission_"+event.extendedProps.driving_transmission_type.replace("both", "unspecified"));
        }
        else {
          $("#edit_event_driving_transmission_type").val("driving_transmission_unspecified");  
        }
        
        // $("#edit_event_customer_ids").trigger({
        //   type: 'select2:select',
        //   params: {
        //     data: event.extendedProps.customers
        //   }
        // }).trigger('change');


        $("#edit_event_session_ids").val(null).trigger("change");
        $("#edit_event_session_ids").find("option").remove();

        $("#edit_event_event_sessions_hr").html("");

        var strSessions = "";

        $.each(event.extendedProps.sessionIds, function(idx, session_id) {
          var option = new Option("Session #"+session_id, session_id, true, true);
          $("#edit_event_session_ids").append(option);
          if(strSessions.length>0) {
            strSessions+=", ";
          }
          strSessions+="Session #"+session_id;
        });
        $("#edit_event_event_sessions_hr").html(strSessions);

        if(event.extendedProps.sessionIds.length==0) {
          $("#edit_event_event_sessions_hr").html("-");
        }


        $("#edit_event_session_ids").trigger({
          type: 'select2:select',
          params: {
            data: event.extendedProps.sessionIds
          }
        }).trigger('change');


        var start = moment(event.start);
        var end = moment(event.end);

        var planningLastSentAt = null;
        if(event.extendedProps.event_type=="type_driving_lesson" && event.extendedProps.last_planning_sent_at!=null) {
          planningLastSentAt = moment(event.extendedProps.last_planning_sent_at);
          $(".edit-event-last-planning-sent-at").removeClass("d-none");
          $("#timeline-lesson-bloc").removeClass("d-none");
          $("#description-lesson-bloc").addClass("col-lg-6");
          $(".edit-event-last-planning-sent-at").find("p").html(planningLastSentAt.format("DD/MM/YYYY à HH:mm"));
          self.setUpTimelineLesson(event.extendedProps.sessionIds[0]);
        }
        else {
          $(".edit-event-last-planning-sent-at").addClass("d-none");
          $("#timeline-lesson-bloc").addClass("d-none");
          $("#description-lesson-bloc").removeClass("col-lg-6");
        }
        console.log(event.extendedProps);
        console.log("planningLastSentAt -> "+planningLastSentAt);

        var duration = moment.duration(end.diff(start));
        var hours = duration.hours();
        var minutes = duration.minutes();

        $("#edit_event_event_type").off("change").on("change", function(e) {
          let value = $(this).val();

          if(["type_not_assigned",
            "type_driving_lesson_additional_hour",
            "type_pack_hour",
            "type_free_driving_lesson",
            "type_external_driving_lesson"
            ].indexOf(value)!=-1 && self.driving_transmission_type=="both") {
            $("#edit_event_driving_transmission_type_part").show();
          }
          else {
            $("#edit_event_driving_transmission_type_part").hide();
          }

          if(["type_free_driving_lesson",
              "type_external_driving_lesson", 
              "type_pack_hour"].indexOf(value)!=-1) {

            $("#edit_event_event_type_part").hide();
          }
          else {
            $("#edit_event_event_type_part").show();
          }

          if(["type_driving_lesson", 
            "type_driving_lesson_duo", 
            "type_driving_lesson_additional_hour",
            "type_initial_assessment",
            "type_simulator",
            "type_pack_hour",
            "type_free_driving_lesson",
            "type_external_driving_lesson",
            "type_return_from_driving_test"
            ].indexOf(value)!=-1){
            $("#edit_event_customer_ids_part").show();
          }
          else {
            $("#edit_event_customer_ids_part").hide();
          }

          if(["type_driving_lesson", 
            "type_driving_lesson_duo", 
            "type_initial_assessment"
            ].indexOf(value)!=-1){
            $("#edit_event_session_ids_part").show();
            $("#edit_event_customer_ids_part").find("p").show();
          }
          else {
            $("#edit_event_session_ids_part").hide();
            $("#edit_event_customer_ids_part").find("p").hide();
          }

          if(["type_free_driving_lesson",
              "type_external_driving_lesson"].indexOf(value)!=-1){
            $("#edit_event_free_explanation_part").removeClass("d-none");
            $("#edit_event_free_explanation").prop("required", true);
          }
          else {
            $("#edit_event_free_explanation_part").addClass("d-none");
            $("#edit_event_free_explanation").prop("required", false);
          }
        });

        console.log(event.extendedProps.customers);

        $("#edit_event_customers_hr").html("");
        var strCustomers = "";
        if(event.extendedProps.customers.length>0) {
          $.each(event.extendedProps.customers, function(idx, customer) {
            if(strCustomers.length>0) {
              strCustomers+=", ";
            }
            strCustomers+=customer.text;
          });

          $("#edit_event_customers_hr").html(strCustomers);
        }
        else {
           $("#edit_event_customers_hr").html("-");
        }

        $("#edit_event_event_type_hr").html(I18n.t("calendar_entry_event_types."+event.extendedProps.event_type));
        $("#edit_event_event_type").val(event.extendedProps.event_type).trigger("change");

        $("#edit_event_special_event_type").val(event.extendedProps.special_event_type).trigger("change");

        $("#edit_event_date").val(start.format("YYYY-MM-DDTHH:mm"));
        $("#edit_event_duration").val(this.pad(hours,2)+":"+this.pad(minutes,2));
      }
    }

    openNewEventModal(_date, _resource) {
      var self = this;


      console.log(_date);
      if(self.selectedDrivingInstructorId==null || self.selectedDrivingInstructorId=="") {
        self.currentDrivingInstructorId = _resource.id;
        if(_resource!=null) {
          self.driving_transmission_type = _resource.extendedProps.driving_transmission_type;
        }
      }
      else {
        self.currentDrivingInstructorId = self.selectedDrivingInstructorId;
      }

      PrettyAlert.clear("#add_event_form");
      $("#add_event_modal").modal();
      $('.modal-backdrop').appendTo("#calendar_card");

      if(self.currentDrivingInstructorId==null || self.currentDrivingInstructorId=="") {
        $("#add_event_driving_instructor_id").html("");

        $.each(this.calendar.currentData.resourceStore, function(idx, resource) {
          $("<option>").attr("value", resource.id).html(resource.title).appendTo("#add_event_driving_instructor_id");
        });

        $("#add_event_driving_instructor_id").val(_resource!=null? _resource.id : null).trigger("change");
      }
      else {
        $("#add_event_driving_instructor_id").html("");
        $("<option>").attr("value", self.currentDrivingInstructorId).html("").appendTo("#add_event_driving_instructor_id");
        $("#add_event_driving_instructor_id").val(self.currentDrivingInstructorId).trigger("change");
      }

      $("#add_event_driving_transmission_type").val(self.driving_transmission_type=="both" ? "driving_transmission_unspecified" : "driving_transmission_"+self.driving_transmission_type);

      var date = null;
      if(_date!=null) {
        date = moment(_date);
      }
      else {
        date = moment();
      }
      
      if(date.hours()==0) {
        date.add(7, "hours");
      }
      $("#add_event_date").val(date.format("YYYY-MM-DDTHH:mm"));
      $("#add_event_duration").val("01:00");

      $("#add_event_event_type").off("change").on("change", function(e) {
        let value = $(this).val();

        if(["type_not_assigned",
          "type_driving_lesson_additional_hour",
          "type_pack_hour",
          "type_free_driving_lesson",
          "type_external_driving_lesson"
          ].indexOf(value)!=-1 && self.driving_transmission_type=="both") {
          $("#add_event_driving_transmission_type_part").show();
        }
        else {
          $("#add_event_driving_transmission_type_part").hide();
        }

        if(["type_driving_lesson", 
          "type_driving_lesson_duo", 
          "type_driving_lesson_additional_hour",
          "type_initial_assessment",
          "type_pack_hour",
          "type_free_driving_lesson",
          "type_external_driving_lesson",
          "type_return_from_driving_test"
          ].indexOf(value)!=-1) {
          $("#add_event_customer_ids_part").show();
        }
        else {
          $("#add_event_customer_ids_part").hide();
        }

        if(["type_driving_lesson", 
          "type_driving_lesson_duo", 
          "type_initial_assessment"
          ].indexOf(value)!=-1) {
          $("#add_event_session_ids_part").show();
          $("#add_event_customer_ids_part").find("p").show();
        }
        else {
          $("#add_event_session_ids_part").hide();
          $("#add_event_customer_ids_part").find("p").hide();
        }

        if(["type_free_driving_lesson",
        "type_external_driving_lesson"].indexOf(value)!=-1) {
          $("#add_event_free_explanation_part").show();
          $("#add_event_free_explanation").prop("required", true);
        }
        else {
          $("#add_event_free_explanation_part").hide();
          $("#add_event_free_explanation").prop("required", false);
        }

      }).trigger("change");

      $("#add_event_customer_ids").val(null).trigger("change");


      $("#add_event_form").unbind("submit").submit(function(e) {
        e.preventDefault();

        self.createEvent($(this).serializeJSON());

      });
    }


    bindEvents() {
      var self = this;

      $(document).off("webkitfullscreenchange mozfullscreenchange fullscreenchange").on("webkitfullscreenchange mozfullscreenchange fullscreenchange", function( event ) {

          if ( document.fullscreen ) {
            self.calendar.setOption('height', window.innerHeight-100);
          }
          else {
            self.calendar.setOption('height', "800");
          }

      });

      $("#entity_selection").on("change", function(e) {
        console.log("entity changed");
        Turbolinks.visit($(this).find("option:selected").data("url"));
      });


      $("#selected_driving_instructor_id").off("change").on("change", function(e) {
        e.preventDefault();

        if(self.calendar!=null) {
          var selectedDate = moment(self.calendar.getDate()).format("Y-MM-DD");

          var _url = $(this).find("option:selected").data("url")+"";

          if(_url.includes("?")) {
            Turbolinks.visit($(this).find("option:selected").data("url")+"&date="+selectedDate);
          }
          else {
            Turbolinks.visit($(this).find("option:selected").data("url")+"?date="+selectedDate);
          }
        }
        else {
          Turbolinks.visit($(this).find("option:selected").data("url"));
        }
      });

      $("#selected_driving_instructor_page").off("change").on("change", function(e) {
        e.preventDefault();

        if(self.calendar!=null) {
          var selectedDate = moment(self.calendar.getDate()).format("Y-MM-DD");

          var _url = $(this).find("option:selected").data("url")+"";

          if(_url.includes("?")) {
            Turbolinks.visit($(this).find("option:selected").data("url")+"&date="+selectedDate);
          }
          else {
            Turbolinks.visit($(this).find("option:selected").data("url")+"?date="+selectedDate);
          }
        }
        else {
          Turbolinks.visit($(this).find("option:selected").data("url"));
        }
      });

      if(self.selectedDrivingInstructorId==null) {
        $("#add_event_driving_instructor_id").select2({
          language: "fr",
          dropdownParent: $('#add_event_modal')
        });
        $("#edit_event_driving_instructor_id").select2({
          language: "fr",
          dropdownParent: $('#edit_event_modal')
        });
      }
      else {
        $("#add_event_driving_instructor_id_part").hide();
        $("#edit_event_driving_instructor_id_part").hide();
      }


      $("#add_event_customer_ids").select2({
        language: "fr",
        maximumSelectionLength: 2,
        minimumInputLength: 2,
        dropdownParent: $('#add_event_modal'),
        ajax: {
            url: self.base_url+'/customers',
            quietMillis: 200,
            data: function (params) {
              var isPackHour = $("#add_event_event_type").val()=="type_pack_hour";
              if(isPackHour) {
                params.display_pack_remaining_time = true;
                params.driving_instructor_id = self.currentDrivingInstructorId;
              }
              return params;
            },
            processResults: function (data) {
              return {
                results: data
              }
            }
        }
      });

      $("#edit_event_customer_ids").select2({
        language: "fr",
        maximumSelectionLength: 2,
        minimumInputLength: 2,
        dropdownParent: $('#edit_event_modal'),
        ajax: {
            url: self.base_url+'/customers',
            quietMillis: 200,
            data: function (params) {
              var isPackHour = $("#edit_event_event_type").val()=="type_pack_hour";
              if(isPackHour) {
                params.display_pack_remaining_time = true;
              }
              params.event_id = self.selectedEvent.id;
              return params;
            },
            processResults: function (data) {
              return {
                results: data
              }
            }
        }
      });


      $("#add_event_session_ids").select2({
        language: "fr",
        maximumSelectionLength: 2,
        dropdownParent: $('#add_event_modal'),
        ajax: {
          url:  self.base_url+'/sessions',
          quietMillis: 200,
          data: function (params) {
            params.instructor_id = self.currentDrivingInstructorId;
            params.date = $("#add_event_date").val();
            return params;
          },
          processResults: function (data) {
            return {
              results: data
            }
          }
        }
      });

      $("#edit_event_session_ids").select2({
        language: "fr",
        maximumSelectionLength: 2,
        dropdownParent: $('#edit_event_modal'),
        ajax: {
          url: self.base_url+'/sessions',
          quietMillis: 200,
          data: function (params) {
            params.instructor_id = self.currentDrivingInstructorId;
            params.date = $("#edit_event_date").val();
            return params;
          },
          processResults: function (data) {
            return {
              results: data
            }
          }
        }
      });
    }

    createEvent(data) {
      var self = this;
      $("#add_to_planning_btn").prop("disabled", true);
      var ajaxData = {
          url: self.base_url,
          data : data,
          method: "POST"
      };

      
      $.ajax(ajaxData).done(function(json) {
        $("#add_to_planning_btn").prop("disabled", false);
        if(json.success) {
          PrettyAlert.clear("#add_event_form");
          $("#add_event_modal").modal("hide");
          self.addEventCalendar(json.event);
        }
        else if(json.error!=null) {
          PrettyAlert.showError({ selector: "#add_event_form", message: "Erreur : "+json.error});
        }
        else {
          PrettyAlert.showError({ selector: "#add_event_form", message: "Une erreur est survenue, veuillez réessayer." });
        }
      }).fail(function(err) {
        PrettyAlert.showError({ selector: "#add_event_form", message: "Une erreur est survenue, veuillez réessayer." });
        $("#add_to_planning_btn").prop("disabled", false);
      });
    }

    notifyPlanningUpdate(id) {
      $("#notify_update_planning_btn").prop("disabled", true);

      var ajaxData = {
          url:  this.base_url+"/calendar_entry/"+id+"/notify",
          data : {},
          method: "PATCH"
      };

      var self = this;
      $.ajax(ajaxData).done(function(json) {
        $("#notify_update_planning_btn").prop("disabled", false);
        if(json.success) {
          PrettyAlert.clear("#edit_event_form");
          $("#edit_event_modal").modal("hide");
          self.calendar.refetchEvents();
        }
        else {
          PrettyAlert.showError({ selector: "#edit_event_form", message: "Une erreur est survenue, veuillez réessayer." });
        }
      }).fail(function(err) {
        PrettyAlert.showError({ selector: "#edit_event_form", message: "Une erreur est survenue, veuillez réessayer." });
        $("#notify_update_planning_btn").prop("disabled", false);
      });
    }

    notifiedPlanningManually(id) {
      $("#notified_planning_manually_btn").prop("disabled", true);

      var ajaxData = {
          url:  this.base_url+"/calendar_entry/"+id+"/notified_manually",
          data : {},
          method: "PATCH"
      };

      var self = this;
      $.ajax(ajaxData).done(function(json) {
        $("#notified_planning_manually_btn").prop("disabled", false);
        if(json.success) {
          PrettyAlert.clear("#edit_event_form");
          $("#edit_event_modal").modal("hide");
          self.calendar.refetchEvents();
        }
        else {
          PrettyAlert.showError({ selector: "#edit_event_form", message: "Une erreur est survenue, veuillez réessayer." });
        }
      }).fail(function(err) {
        PrettyAlert.showError({ selector: "#edit_event_form", message: "Une erreur est survenue, veuillez réessayer." });
        $("#notified_planning_manually_btn").prop("disabled", false);
      });
    }

    updateEvent(id, data) {
      $("#update_event_btn").prop("disabled", true);

      var ajaxData = {
          url: this.base_url+"/"+id,
          data : data,
          method: "PATCH"
      };

      var self = this;
      $.ajax(ajaxData).done(function(json) {
        $("#update_event_btn").prop("disabled", false);
        if(json.success) {
          PrettyAlert.clear("#edit_event_form");
          $("#edit_event_modal").modal("hide");
          self.updateEventCalendar(json.event);
        }
        else if(json.error!=null) {
          PrettyAlert.showError({ selector: "#edit_event_form", message: "Erreur : "+json.error});
          self.calendar.refetchEvents();
        }
        else {
          PrettyAlert.showError({ selector: "#edit_event_form", message: "Une erreur est survenue, veuillez réessayer."});
        }
      }).fail(function(err) {
        PrettyAlert.showError({ selector: "#edit_event_form", message: "Une erreur est survenue, veuillez réessayer."});
        $("#update_event_btn").prop("disabled", false);
      });
    }

    deleteEvent(id) {
      var ajaxData = {
          url: this.base_url+"/"+id,
          method: "DELETE"
      };

      var self = this;
      $.ajax(ajaxData).done(function(json) {
        self.calendar.getEventById(id).remove();
      }).fail(function(err) {
        
      });
    }

    addEventCalendar(event) {
      var cEvent = this.calendar.addEvent(event, true);
    }

    updateEventCalendar(event) {
      console.log("Start update calendar");
      let start = new Date();
      var cEvent = this.calendar.getEventById(event.id);
      cEvent.setDates(event.start, event.end);
      cEvent.setProp("color", event.color);
      cEvent.setProp("textColor", event.textColor);
      cEvent.setProp("title", event.title);
      cEvent.setResources([event.resourceId]);
      cEvent.setExtendedProp("customerIds", event.customerIds);
      cEvent.setExtendedProp("customers", event.customers);
      cEvent.setExtendedProp("sessionIds", event.sessionIds);
      cEvent.setExtendedProp("event_type", event.event_type);
      cEvent.setExtendedProp("special_event_type", event.special_event_type);
      cEvent.setExtendedProp("place_indication", event.place_indication);
      cEvent.setExtendedProp("description", event.description);
      cEvent.setExtendedProp("driving_test_description", event.driving_test_description);
      cEvent.setExtendedProp("driving_transmission_type", event.driving_transmission_type);
      cEvent.setExtendedProp("free_explanation", event.free_explanation);

      let stop = new Date();

      console.log("Stop updating calendar");
      console.log(stop-start);
    }

    onDataChanged(data) {

    }

    onDestroy() {
      // destroy select2
      $("#entity_selection").select2("destroy");
      $("#selected_driving_instructor_id").select2("destroy");
      $("#edit_event_driving_instructor_id").select2("destroy");
      $("#edit_event_driving_instructor_id").select2("destroy");
      $("#add_event_customer_ids").select2("destroy");
      $("#edit_event_customer_ids").select2("destroy");
      $("#add_event_session_ids").select2("destroy");
      $("#edit_event_session_ids").select2("destroy");   
      this.calendar.destroy();

      //close modal
      $("#add_event_modal").modal("hide");
      $("#edit_event_modal").modal("hide");
    }

    delayedBindAdditionalCalendarFeatures() {
      var self = this;
      setTimeout(function() {
        //console.log("bindAdditionalCalendarFeatures");
        //console.log("Found "+$(".fc-resource").length+" resources");
        $(".fc-col-header-cell.fc-resource").addClass("fc-clickable").unbind("click").click(function() {
          //console.log($(this).data());
          var args = "driving_instructor_id="+$(this).data("resourceId")+"&date="+$(this).data("date");
          if(self.city_id!=null && self.city_id.length>0) {
            args += "&city_id="+self.city_id;
          }
          Turbolinks.visit(self.base_url+"?"+args);
        });

        $(".fc-col-header-cell.fc-day").addClass("fc-clickable").unbind("click").click(function() {
          //console.log($(this).data());
          var args = "&date="+$(this).data("date");
          if(self.city_id!=null && self.city_id.length>0) {
            args += "&city_id="+self.city_id;
          }
          Turbolinks.visit(self.base_url+"?"+args);
        });

      }, 500);
    }

   getEventCustomerNames(event) {
    var names = [];

    $.each(event.extendedProps.customers, function(idx, customer) {
      names.push(customer.text);
    });

    return names;
   }

   pad(n, width, _z) {
    var z = _z || '0';
    n = n + '';
    return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
  }


  setUpTimelineLesson( sessionId ) {
    let $timeline = $('#timeline-lesson-bloc .lesson-timeline')
    $timeline.empty();
    $.ajax({
      url: $timeline.data('url'),
      type: 'GET',
      data : { session_id: sessionId },
      success: this.loadTimeLine
    })
  }

  loadTimeLine( html ) {
    let $timeline = $('#timeline-lesson-bloc .lesson-timeline');
    $timeline.html(html);
  }
}
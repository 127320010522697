export default class DrivingTestsLevelAlerts {
    constructor() {
        this.bindEvents();
    }

    bindEvents() {
        $(".open-alert-comment-modal").off("click").on("click", function(e) {
            $("#modal-alert-comment").modal();
            $("#modal-alert-comment .modal-title").html($(this).data("title"));
            $("#modal-alert-comment .modal-body").html($(this).data("content").split("\n").join("<br/>"));
          });
    }
}
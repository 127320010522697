export default class DrivingTestsPostponed {
    constructor() {
        this.bindEvents();
    }

    bindEvents() {
        $('#filter_period').change(function() {
            if ($(this).val() === 'custom') {
                $('.custom_period_fields').removeClass('d-none');
            } else {
                $('.custom_period_fields').addClass('d-none');
            }
        });


        $(".cancel-candidate-btn").off("click").on("click", function(e) {
            e.preventDefault();

            $("#cancellation-reason-btn-group > label").on("click", function(e) {
                $("#cancellation-reason-btn-group > label").removeClass("active").removeClass("btn-primary").addClass("btn-secondary");
                $(this).addClass("active").addClass("btn-primary").removeClass("btn-secondary");
            });

            var actionURL = $(this).data("actionUrl");
            $("#cancel_candidate_full_name").html($(this).data("fullName"));

            console.log($(this).data("cancellationReason"));
            $("#radio_" + $(this).data("cancellationReason")).trigger("click");

            $("#cancel_candidate_form").attr("action", actionURL);
            $("#cancel_candidate_modal").modal();
        });

        $(".open-cancelled-comment-modal").off("click").on("click", function(e) {
            e.preventDefault();

            var actionURL = $(this).data("action_url");

            $("#cancelled_comment_form").attr("action", actionURL);
            $("#cancelled_comment").val($(this).data("content"));
            $("#cancelled_comment_modal").modal();
        });
    }
}
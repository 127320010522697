import { Calendar } from '@fullcalendar/core';
import frenchTranslation from '@fullcalendar/core/locales/fr';
import bootstrapPlugin from '@fullcalendar/bootstrap';
import interactionPlugin from '@fullcalendar/interaction';
import timeGrid from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import scrollgridPlugin from '@fullcalendar/scrollgrid';

import CalendarEntry from "../utils/CalendarEntry";


export default class DrivingCoursesShowSession {
    constructor() {

        var self = this;
        this.selectedEvent = null;

        this.session_id = $("#driving_course_calendar").data("session_id");
        this.business_hours = $("#driving_course_calendar").data("working_hours_schedule");
        this.initial_date = $("#driving_course_calendar").data("initial_date");
        
        this.driving_instructors = [];


        if($("#driving_course_calendar").length>0) {
          this.initCalendar();
        }

        

        this.bindEvents();
    }

    initCalendar() {
      var self = this;
      this.calendar = new Calendar(document.getElementById('driving_course_calendar'), {
          schedulerLicenseKey: 'CC-Attribution-NonCommercial-NoDerivatives',
          themeSystem: "bootstrap",
          locale: frenchTranslation,
          initialView: "timeGridWeek",
          initialDate: (this.initial_date==null ? new Date() : this.initial_date),
          slotDuration: "00:30:00",
          slotMinTime: "06:00:00",
          slotMaxTime: "21:00:00",
          dayMaxEvents: true,
          dayMinWidth: 200,
          allDaySlot: false,
          //editable: true,
          selectable: true,
          plugins: [ bootstrapPlugin, interactionPlugin, scrollgridPlugin, timeGrid, listPlugin ],
          headerToolbar: { 
            left: "prev,next today", 
            center: "title",
            right: ""
          },

          // views: {
          //     dayGridWeek: {
          //       duration: { days: 7 },
          //       buttonText: 'Jour',
          //       slotMinWidth: 20,
          //       resourceAreaWidth: "15%"
          //     }
          // },
          businessHours: self.business_hours,
          events: {
            url: '/planning/events',
            method: 'GET',
            extraParams: {
              session_id: self.session_id,
            },
            failure: function() {
              console.log('there was an error while fetching events!');
            },
            //backgroundColor: "#3245B2",
            //textColor: 'white' // a non-ajax option
          },
          eventSourceSuccess: function(content, xhr) {
            console.log("eventSourceSuccess -> "+content.events.length);
            return content.events;
          },
          resources: {
            url: '/planning/driving_instructors',
            method: 'GET',
            success: function(raw_resources, xhr) {

            }
          },
          //dateClick: function(arg) { self.onDateClicked(arg) },
          eventClick: function(info) { self.onEventClicked(info) },
          eventContent: function(arg) {
            /*console.log(arg.event.extendedProps);
            console.log("eventContent called");
            let title = document.createElement("div")
            let customerName = document.createElement("div")
            let icon = document.createElement('i')

            icon.className = 'ri-user-fill';
            title.appendChild(icon);

            if (arg.event.extendedProps.customerIds.length > 1) {
              let icon2 = document.createElement('i')
              icon2.className = 'ri-user-fill';
              title.appendChild(icon2);
            }

            title.innerHTML += " "+arg.event.title;

            customerName.innerHTML = self.getEventCustomerNames(arg.event).join(" et ");

            let arrayOfDomNodes = [ title, customerName ]
            return { domNodes: arrayOfDomNodes }*/

             var entry = new CalendarEntry(arg);

            return entry.render().getRootNode();
          }
        });

        this.calendar.render();
    }

    onDateClicked(arg) {
      this.openNewEventModal(arg.date, arg.resource);
    }

    onEventClicked(e) {
      var self = this;
      var event = e.event;
      this.selectedEvent = event;
      e.jsEvent.preventDefault();

      PrettyAlert.clear("#edit_event_form");

      $("#edit_event_form").unbind("submit").submit(function(e) {
        e.preventDefault();

        self.updateEvent(event.id, $(this).serializeJSON());

      });

      $("#delete_event_btn").unbind("click").click(function(e) {
        e.preventDefault();

        $("#edit_event_modal").modal("hide");
        self.deleteEvent(event.id);
      });

      $("#edit_event_modal").modal();

      $("#edit_event_driving_instructor_id").html("");

      $.each(this.calendar.currentData.resourceStore, function(idx, resource) {
        $("<option>").attr("value", resource.id).html(resource.title).appendTo("#edit_event_driving_instructor_id");
      });

      $("#edit_event_place_indication").val(event.extendedProps.place_indication);
      $("#edit_event_description").val(event.extendedProps.description);
      $("#edit_event_driving_instructor_id").val(event._def.resourceIds[0]).trigger("change");

      $("#edit_event_customer_ids").val(null).trigger("change");

      $.each(event.extendedProps.customers, function(idx, customer) {
        var option = new Option(customer.text, customer.id, true, true);
        $("#edit_event_customer_ids").append(option);
      });

      $("#edit_event_customer_ids").trigger({
        type: 'select2:select',
        params: {
          data: event.extendedProps.customers
        }
      }).trigger('change');

      var start = moment(event.start);
      var end = moment(event.end);

      var duration = moment.duration(end.diff(start));
      var hours = duration.hours();
      var minutes = duration.minutes();

      $("#edit_event_event_type").val(event.extendedProps.event_type);

      $("#edit_event_date").val(start.format("YYYY-MM-DDTHH:mm"));
      $("#edit_event_duration").val(this.pad(hours,2)+":"+this.pad(minutes,2));

    }

    openNewEventModal(_date, _resource) {
      var self = this;
      PrettyAlert.clear("#add_event_form");
      $("#add_event_modal").modal();

      $("#add_event_driving_instructor_id").html("");

      $.each(this.calendar.currentData.resourceStore, function(idx, resource) {
        $("<option>").attr("value", resource.id).html(resource.title).appendTo("#add_event_driving_instructor_id");
      });

      $("#add_event_driving_instructor_id").val(_resource!=null? _resource.id : null).trigger("change");

      var date = null;
      if(_date!=null) {
        date = moment(_date);
      }
      else {
        date = moment();
      }
      
      if(date.hours()==0) {
        date.add(7, "hours");
      }
      $("#add_event_date").val(date.format("YYYY-MM-DDTHH:mm"));
      $("#add_event_duration").val("01:00");

      $("#add_event_customer_ids").val(null).trigger("change");


      $("#add_event_form").unbind("submit").submit(function(e) {
        e.preventDefault();

        self.createEvent($(this).serializeJSON());

      });
    }


    bindEvents() {

      $('.edit_driving_course_modal').off("click").on('click', function (e) {
        let loadurl = $(this).data("href");
        let $modal = $("#edit_driving_course_modal");
         $modal.modal();
         $modal.find('.modal-body').load(loadurl);
      });

      $("#add_event_customer_ids").select2({
        language: "fr",
        maximumSelectionLength: 2,
        minimumInputLength: 2,
        ajax: {
            url: '/planning/customers',
            quietMillis: 200,
            processResults: function (data) {
              return {
                results: data
              }
            }
        }
      });

      $("#edit_event_customer_ids").select2({
        language: "fr",
        maximumSelectionLength: 2,
        minimumInputLength: 2,
        ajax: {
            url: '/planning/customers',
            quietMillis: 200,
            processResults: function (data) {
              return {
                results: data
              }
            }
        }
      });
    }

    createEvent(data) {
      $("#add_to_planning_btn").prop("disabled", true);
      var ajaxData = {
          url: "/planning",
          data : data,
          method: "POST"
      };

      var self = this;
      $.ajax(ajaxData).done(function(json) {
        $("#add_to_planning_btn").prop("disabled", false);
        if(json.success) {
          PrettyAlert.clear("#add_event_form");
          $("#add_event_modal").modal("hide");
          self.addEventCalendar(json.event);
        }
        else {
          PrettyAlert.showError({ selector: "#add_event_form", message: "Une erreur est survenue, veuillez réessayer." });
        }
      }).fail(function(err) {
        PrettyAlert.showError({ selector: "#add_event_form", message: "Une erreur est survenue, veuillez réessayer." });
        $("#add_to_planning_btn").prop("disabled", false);
      });
    }

    updateEvent(id, data) {
      $("#update_event_btn").prop("disabled", true);

      var ajaxData = {
          url: "/planning/"+id,
          data : data,
          method: "PATCH"
      };

      var self = this;
      $.ajax(ajaxData).done(function(json) {
        $("#update_event_btn").prop("disabled", false);
        if(json.success) {
          PrettyAlert.clear("#edit_event_form");
          $("#edit_event_modal").modal("hide");
          self.updateEventCalendar(json.event);
        }
        else {
          PrettyAlert.showError({ selector: "#edit_event_form", message: "Une erreur est survenue, veuillez réessayer." });
        }
      }).fail(function(err) {
        PrettyAlert.showError({ selector: "#edit_event_form", message: "Une erreur est survenue, veuillez réessayer." });
        $("#update_event_btn").prop("disabled", false);
      });
    }

    deleteEvent(id) {
      var ajaxData = {
          url: "/planning/"+id,
          method: "DELETE"
      };

      var self = this;
      $.ajax(ajaxData).done(function(json) {
        self.calendar.getEventById(id).remove();
      }).fail(function(err) {
        
      });
    }

    addEventCalendar(event) {
      var cEvent = this.calendar.addEvent(event);
    }

    updateEventCalendar(event) {
      var cEvent = this.calendar.getEventById(event.id);
      cEvent.setDates(event.start, event.end);
      cEvent.setProp("color", event.color);
      cEvent.setProp("textColor", event.textColor);
      cEvent.setProp("title", event.title);
      cEvent.setResources([event.resourceId]);
      cEvent.setExtendedProp("customerIds", event.customerIds);
      cEvent.setExtendedProp("customers", event.customers);
      cEvent.setExtendedProp("event_type", event.event_type);
      cEvent.setExtendedProp("place_indication", event.place_indication);
      cEvent.setExtendedProp("description", event.description);
    }

    onDataChanged(data) {

    }

    onDestroy() {
        
    }

     onEditFormloaded() {
      console.log("onEditFormloaded() called");

      $("#edit_driving_course_form").off("submit").on("submit", function(e) {
          e.preventDefault();
          console.log($(this).serializeJSON());
          let ajaxData = {
              url: $(this).attr("action"),
              data : $(this).serializeJSON(),
              method: "PUT"
          };

          $.ajax(ajaxData).done(function(json) {
              application.successNotification(json['message']);
              $("#edit_driving_course_modal").modal('hide');
          }).fail(function (error){
              application.errorNotification(error.responseJSON['message']);
          });
      });
    }


   getEventCustomerNames(event) {
    var names = [];

    $.each(event.extendedProps.customers, function(idx, customer) {
      names.push(customer.text);
    });

    return names;
   }

   pad(n, width, _z) {
    var z = _z || '0';
    n = n + '';
    return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
  }
}
export default class SessionNotificationsIndex {

    constructor() {
        this.dataTable = null;

        this.bindEvents();
        //window.application.setOnDataChangeListener(this);
    }


    bindEvents() {
        let self = this;


        this.dataTable = $('#ajax-datatable').DataTable({
            "responsive": false,
            searchDelay: 350,
            "order": [[ 1, "desc" ]],
            "serverSide": true,
            pageLength: 100,
            "ajax": {
                'url':"/session_notifications/ajax_index",
                'data': function(data){
                    data.city_id = $("#prospect_city_id").val();
                    data.department_ids = $("#prospect_department_ids").val();
                    data.prospect_formula_id = $("#prospect_formula_id option:checked").val();
                    data.transmission = $("#prospect_transmission option:checked").val();
                    data.only_notified = $("#prospect_notified").is(":checked");
                    data.only_enabled = $("#prospect_enabled").is(":checked");
                    data.search["value"] = $("#prospect_search").val();
                    data.prospect_period = $("#prospect_period").val();
                    data.custom_start_date = $("#custom_start_date").val();
                    data.custom_end_date = $("#custom_end_date").val();
                    data.account_status = $("#prospect_account_status").val();
                }
            },
            "columns": [
                { "data": "is_enabled" },
                { "data": "created_at" },
                { "data": "customer_id" },
                { "data": "email" },
                { "data": "phone" },
                { "data": "driving_formula" },
                { "data": "transmission" },
                { "data": "city_id" },
                //{ "data": "places" },
                { "data": "sent_at" },
                { "data": "actions" },
            ],
            "language": {
                url: '/data-tables/locales/' + I18n.locale + '.json'
            },
            "dom": "<'row'<'col-sm-12'tr>>" +
                "<'row'<'col-sm-12 col-md-5'i><'col-sm-12 col-md-7'p>>",
            drawCallback:function(){
                $(".dataTables_paginate > .pagination").addClass("pagination-rounded");
                $('[data-toggle=popover]').popover();
            },
        });

        $("#prospect_department_ids").change(function(){self.dataTable.draw();});
        $('#prospect_city_id').change(function(){self.dataTable.draw();});
        $('#prospect_formula_id').change(function(){self.dataTable.draw();});
        $('#prospect_transmission').change(function(){self.dataTable.draw();});
        $('#prospect_notified').change(function(){self.dataTable.draw();});
        $('#prospect_enabled').change(function(){self.dataTable.draw();});
        $('#prospect_search').keyup(function(){self.dataTable.draw();});
        $('#prospect_period').change(function() {
            if ($(this).val() === 'custom') {
                $('#custom_period_fields').removeClass('d-none');
            } else {
                $('#custom_period_fields').addClass('d-none');
                self.dataTable.draw();
            }
        });
        $('#custom_start_date, #custom_end_date').change(function() {
            self.dataTable.draw();
        });

        $('#prospect_account_status').change(function() {
            self.dataTable.draw();
        });

        $('#refresh_table').click(function() {
            self.dataTable.draw();
        });

        // Initialiser le champ de sélection des clients avec Select2
        $('.customer-search').each(function() {
            var $select = $(this);
            $select.select2({
                ajax: {
                    url: $select.data('url'),
                    dataType: 'json',
                    delay: 250,
                    data: function(params) {
                        return {
                            term: params.term
                        };
                    },
                    processResults: function(data) {
                        return {
                            results: data
                        };
                    },
                    cache: true
                },
                minimumInputLength: 1,
                placeholder: $select.data('placeholder'),
                language: {
                    inputTooShort: function() {
                        return "Veuillez entrer 1 caractère ou plus";
                    }
                }
            });
        });

        // Mettre à jour les départements en fonction de la ville sélectionnée
        $('#city_id').change(function() {
            var cityId = $(this).val();
            var $departmentSelect = $('#department_ids');
            if (cityId) {
                $departmentSelect.prop('disabled', false);
                $.ajax({
                    url: $(this).data('url'),
                    data: { city_id: cityId },
                    success: function(data) {
                        $departmentSelect.empty();
                        $.each(data, function(index, department) {
                            $departmentSelect.append(new Option(department.name, department.id));
                        });
                    }
                });
            } else {
                $departmentSelect.prop('disabled', true);
                $departmentSelect.empty();
                $departmentSelect.append(new Option($departmentSelect.data('placeholder'), ''));
            }
        });

        this.dataTable.on('preDraw', function() {
            console.log('preDraw');
            $('#loading-indicator').removeClass('d-none').addClass("d-inline-block");
        });

        this.dataTable.on('draw', function () {
            console.log('draw');
            $('#loading-indicator').addClass('d-none').removeClass("d-inline-block");
            $('[data-toggle="tooltip"]').tooltip();
            self.bindToggleNotificationButtons();
            self.updateTotalSessions();
        });
    }

    updateTotalSessions() {
        let info = this.dataTable.page.info();
        $('#total_sessions').text(info.recordsDisplay);
    }

    bindToggleNotificationButtons() {
        let self = this;
        $('.toggle-notification').off('click').on('click', function(e) {
            e.preventDefault();
            let $button = $(this);
            let url = $button.data('href');
            $button.prop('disabled', true);
            $button.html('<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>');
            $.ajax({
                url: url,
                type: 'PATCH',
                success: function() {
                    self.dataTable.draw(false);
                },
                error: function() {
                    alert('Erreur lors de la mise à jour de la notification.');
                    $button.prop('disabled', false);
                    $button.html($button.data('original-text'));
                }
            });
        });
    }

    onDataChanged(data) {
    }

    onDestroy() {

        this.dataTable.destroy();
        this.dataTable = null;
    }
}
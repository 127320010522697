import { Calendar } from '@fullcalendar/core';
import frenchTranslation from '@fullcalendar/core/locales/fr';
import bootstrapPlugin from '@fullcalendar/bootstrap';
import interactionPlugin, { Draggable } from '@fullcalendar/interaction';
import timeGrid from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import scrollgridPlugin from '@fullcalendar/scrollgrid';

import CalendarEntry from "../utils/CalendarEntry";


export default class PlannificationIndex {
  
  constructor() {

    var self = this;
    if($("#planification_calendar").length>0) {
      this.entity_id = $("#planification_calendar").data("entity_id");
      this.instructor_id = $("#planification_calendar").data("driving_instructor_id");
      this.business_hours = $("#planification_calendar").data("working_hours_schedule");
      this.selected_session1 = $("#planification_calendar").data("selected_session1");
      this.selected_session2 = $("#planification_calendar").data("selected_session2");
      this.initial_date = $("#planification_calendar").data("initial_date");

      if(this.selected_session1!=null) {
        this.total_hours = parseInt($("#total_hours").html());
        this.duo_count = parseInt($("#duo_count").html());
        this.base_remaining_hours = parseInt($("#total_hours").html());
      }
      else {
        this.total_hours = 0;
        this.base_remaining_hours = 0;
      }


      this.uid = 0;
      this.unsaved_events = {};
      this.selectedEvent = null;


      new Draggable(document.getElementById('droppable-events'), {
        itemSelector: '.fc-event',
        eventData: function(eventEl) {
          var data = $(eventEl).data("event");
          data.startEditable = true;
          data.uid = self.uid;
          self.uid++;
          console.log(data);
          return data;
        }
      });

      if(window.application.context=="bo") {
            this.base_url = "/planning/"+this.entity_id;
          }
          else {
            this.base_url = "/planning/";
          }

      this.calendar = new Calendar(document.getElementById('planification_calendar'), {
        schedulerLicenseKey: 'CC-Attribution-NonCommercial-NoDerivatives',
        themeSystem: "bootstrap",
        locale: frenchTranslation,
        initialView: "timeGridWeek",
        initialDate: (this.initial_date==null ? new Date() : this.initial_date),
        slotDuration: "00:30:00",
        slotMinTime: "06:00:00",
        slotMaxTime: "21:00:00",
        dayMaxEvents: true,
        dayMinWidth: 250,
        allDaySlot: false,
        editable: true,
        eventStartEditable: false,
        eventDurationEditable: false,
        selectable: true,
        droppable: true,
        eventOverlap: false,
        plugins: [ bootstrapPlugin, interactionPlugin, scrollgridPlugin, timeGrid, listPlugin ],
        headerToolbar: { 
          left: "prev,prev_day next_day,next fullscreen",
          center: "title", 
          right: (self.selected_session1==null ? "validate_entries fill_voids" : "cancel_manual_session validate_manual_session")
        },
        bootstrapFontAwesome: {
          fullscreen: "fa-expand-arrows-alt",
          prev: "fa-backward",
          next: "fa-forward",
          prev_day: "fa-chevron-left",
          next_day: "fa-chevron-right",
        },
        views: {
          timeGridWeek: { buttonText: "Semaine", days: 7, dateAlignment: "day" }
        },
        customButtons: {
          prev: {
            click: function() {
              var startD = self.calendar.currentDataManager.state.dateProfile.currentRange.start;
              var endD = self.calendar.currentDataManager.state.dateProfile.currentRange.end;

              startD.setHours(0);
              endD.setHours(0);

              self.calendar.setOption('visibleRange', {
                start: startD.addDays(-7),
                end: endD.addDays(-7)
              });
              self.calendar.incrementDate({ days: -7, hours: 0 });
            }
          },
          next: {
            click: function() {
              var startD = self.calendar.currentDataManager.state.dateProfile.currentRange.start;
              var endD = self.calendar.currentDataManager.state.dateProfile.currentRange.end;

              startD.setHours(0);
              endD.setHours(0);

              self.calendar.setOption('visibleRange', {
                start: startD.addDays(7),
                end: endD.addDays(7)
              });
              self.calendar.incrementDate({ days: 7, hours: 0 });
            }
          },
          prev_day: {
            click: function() {
              var startD = self.calendar.currentDataManager.state.dateProfile.currentRange.start;
              var endD = self.calendar.currentDataManager.state.dateProfile.currentRange.end;

              startD.setHours(0);
              endD.setHours(0);

              self.calendar.setOption('visibleRange', {
                start: startD.addDays(-1),
                end: endD.addDays(-1)
              });
              // self.calendar.incrementDate(-24*3600*1000)
              self.calendar.incrementDate({ days: -1, hours: 0 });
            }
          },
          next_day: {
            click: function() {
              //self.calendar.incrementDate("24:00")

              var startD = self.calendar.currentDataManager.state.dateProfile.currentRange.start;
              var endD = self.calendar.currentDataManager.state.dateProfile.currentRange.end;

              startD.setHours(0);
              endD.setHours(0);

              self.calendar.setOption('visibleRange', {
                start: startD.addDays(1),
                end: endD.addDays(1)
              });
              // self.calendar.incrementDate(24*3600*1000)
              self.calendar.incrementDate({ days: 1, hours: 0 });
            }
          },
          fullscreen: {
            click: function() {
              var elem = document.getElementById('calendar_card');
              if(elem.webkitRequestFullScreen!=undefined) {
                if(document.webkitFullscreenElement) {
                  document.webkitCancelFullScreen();
                }
                else {
                  elem.webkitRequestFullScreen();
                }
              }
              else {
                if(document.fullscreenElement) {
                  document.exitFullscreen();
                }
                else {
                  elem.requestFullscreen();
                }
              }
            }
          },
          add_manually: {
            text: "Ajout manuel",
            click: function() {
              $("#manual_session_add_modal").modal();
              $('.modal-backdrop').appendTo("#calendar_card");
            }
          },
          fill_voids: {
            text: "Remplir les trous de créneaux libres",
            click: function() {
              var d = moment(self.calendar.getDate());
              $("#fill_voids_date").val(d.format("YYYY-MM-DD"));
              $("#fill_voids_modal").modal();
              $('.modal-backdrop').appendTo("#calendar_card");
            }
          },
           validate_entries: {
            text: "Valider les ajouts",
            click: function() {
              $("#validate_manual_entry_add_modal").modal();
              $('.modal-backdrop').appendTo("#calendar_card");
            }
          },
          cancel_manual_session: {
            text: "Annuler",
            click: function() {
              $("#cancel_manual_session_modal").modal();
              $('.modal-backdrop').appendTo("#calendar_card");
            }
          },

          validate_manual_session: {
            text: (self.selected_session2!=null ? "Valider les stages" : "Valider le stage"),
            click: function() {
              $("#validate_manual_session_modal").modal();
              $('.modal-backdrop').appendTo("#calendar_card");
            }
          },
        },
        businessHours: self.business_hours,
        drop: function (dropInfo) {
          // console.log("----- event "+$(dropInfo.draggedEl).data("event").uid+" dropped (drop) -----");
          // console.log(dropInfo);
          $(dropInfo.draggedEl).data("event").date = dropInfo.dateStr;
          
          self.unsaved_events[$(dropInfo.draggedEl).data("event").uid] = Object.assign({}, $(dropInfo.draggedEl).data("event"), {});

          if(self.selected_session1==null) {
            $(".fc-validate_entries-button").attr("disabled", false);
            $(".fc-fill_voids-button").attr("disabled", true);
            $(".fc-add_manually-button").attr("disabled", true);
          }
          else {
            self.recomputeRemainingHours();
          }
        },
        eventDrop(dropInfo) {
          // console.log("----- event dropped (eventDrop) -----");
          // console.log(dropInfo);
          // console.log(dropInfo.event.start);
          // console.log("--------------------------------------");

          var start = moment(dropInfo.event.start);
          var end = moment(dropInfo.event.end);

          var duration = moment.duration(end.diff(start));
          var hours = duration.hours();
          var minutes = duration.minutes();

          if(self.unsaved_events[dropInfo.event.extendedProps.uid]!=null) {

            self.unsaved_events[dropInfo.event.extendedProps.uid].date = start.toDate().toISOString();
            self.unsaved_events[dropInfo.event.extendedProps.uid].duration = self.pad(hours,2)+":"+self.pad(minutes,2);
            
            self.recomputeRemainingHours();
          }
          else {
            self.updateEvent(dropInfo.event.id, {
              is_drop: true,
              calendar_event: {
                date: start.toDate().toISOString(),
                duration : self.pad(hours,2)+":"+self.pad(minutes,2)
              }
            });
          }

        },
        events: {
          url: self.base_url+'/events',
          method: 'GET',
          extraParams: {
            instructor_id: this.instructor_id
          },
          failure: function() {
            console.log('there was an error while fetching events!');
          },
          //color: 'yellow',   // a non-ajax option
          textColor: 'black' // a non-ajax option
        },
        eventSourceSuccess: function(content, xhr) {
          console.log("eventSourceSuccess -> "+content.events.length);
          //return content.events;
          let events = content.events;
          let holidays = content.holidays;

            if(self.selected_session1!=null) {
               return events;
            }
            else {

            for(var i=0;i<events.length;i++) {
              events[i]["startEditable"] = true;
            }

            return events.concat(holidays);
          }
        },
        resources: {
          url: self.base_url+'/driving_instructors',
          method: 'GET',
          success: function(raw_resources, xhr) {

          }
        },
        dateClick: function(arg) { self.onDateClicked(arg) },
        eventClick: function(info) { 
          console.log(info);
          if(info.event.extendedProps.uid!=null) {
            self.onUnsavedEventClicked(info);
          }
          else {
            self.onEventClicked(info)
          }
        },
        loading: function(isLoading) {      
          //console.log("isLoading -> "+isLoading);
        },
        eventContent: function(arg) {

          if(arg.event.display!=null && arg.event.display=="background") {
            return {};
          }
          else {
            var entry = new CalendarEntry(arg);
            return entry.render().getRootNode();
          }
        }
      });

      this.calendar.render();
    }

    this.bindEvents();
  }

  bindEvents() {

    var self = this;

    $(document).off("webkitfullscreenchange mozfullscreenchange fullscreenchange").on("webkitfullscreenchange mozfullscreenchange fullscreenchange", function( event ) {

      if ( document.fullscreen ) {
        self.calendar.setOption('height', window.innerHeight-100);
      }
      else {
        self.calendar.setOption('height', "800");
      }

    });

    $("#manual_add_btn").off("click").on("click", function(e) {
      e.preventDefault();

      var quantity = $("#generation_form").find('input[name="quantity"]').val();
      var formulaId = $("#generation_form").find('select[name="formula_id"]').val();
      var fromDate = $("#generation_form").find('input[name="from_date"]').val();

      console.log("Quantité : "+quantity);
      console.log("Formula : "+formulaId);
      console.log("from_date : "+fromDate);

      $("#manual_session_add_quantity").val(quantity);
      $("#manul_session_formula_id").val(formulaId);
      $("#manual_from_date").val(fromDate);

      $("#manual_session_add_modal").modal();
    });

    $(".fc-validate_entries-button").attr("disabled", true);

    $("#entity_selection").on("change", function(e) {
      console.log("entity changed");
      Turbolinks.visit($(this).find("option:selected").data("url"));
    });

    $("#driving_instructor_selection").on("change", function(e) {
      console.log("instructor changed");
      Turbolinks.visit($(this).find("option:selected").data("url"));
    });

    $("#city_selection").on("change", function(e) {
      console.log("city changed");
      Turbolinks.visit($(this).find("option:selected").data("url"));
    });

    $("#validate_session_form").off("submit").on("submit", function(e) {
      e.preventDefault();

       var ajaxData = {
        url: $("#validate_session_form").attr("action"),
        data : {
          calendar_entries: self.unsaved_events,
          noredirect: 1
        },
        method: "PATCH"
      };

      $.ajax(ajaxData).done(function(json) {
        $("#validate_session_btn").prop("disabled", false);
        if(json.success) {
          PrettyAlert.clear("#validate_session_form");
          $("#validate_manual_session_modal").modal("hide");
          Turbolinks.visit(window.location.href);

        }
        else {
          PrettyAlert.showError({ selector: "#validate_session_form", message: "Une erreur est survenue, veuillez réessayer." });
        }
      }).fail(function(err) {
        PrettyAlert.showError({ selector: "#validate_session_form", message: "Une erreur est survenue, veuillez réessayer." });
        $("#validate_session_btn").prop("disabled", false);
      });


    });

    $("#validate_manual_entry_add_form").off("submit").on("submit", function(e) {
      e.preventDefault();

       var ajaxData = {
        url: $("#validate_manual_entry_add_form").attr("action"),
        data : {
          calendar_entries: self.unsaved_events
        },
        method: "POST"
      };

      $.ajax(ajaxData).done(function(json) {
        $("#validate_manual_entry_add_btn").prop("disabled", false);
        if(json.success) {
          PrettyAlert.clear("#validate_manual_entry_add_form");
          $("#validate_manual_entry_add_modal").modal("hide");
          //Turbolinks.visit(window.location.href);
          self.clearUnsavedEvents();
          self.calendar.refetchEvents();
        }
        else {
          PrettyAlert.showError({ selector: "#validate_manual_entry_add_form", message: "Une erreur est survenue, veuillez réessayer." });
        }
      }).fail(function(err) {
        PrettyAlert.showError({ selector: "#validate_manual_entry_add_form", message: "Une erreur est survenue, veuillez réessayer." });
        $("#validate_manual_entry_add_btn").prop("disabled", false);
      });


    });


    $("#fill_voids_form").off("submit").on("submit", function(e) {
      $("#validate_fill_voids_btn").prop("disabled", true);
      $("#fill_voids_modal").modal("hide");
    });


    $("#generation_form").find('select[name="formula_id"]').off("change").change(function(e) {
      var selection = $($(this).find('option:selected')[0]);

      if(selection.data("generate_automatically")==true) {
        $("#automatic_add_btn").prop("disabled", false);
      }
      else {
        $("#automatic_add_btn").prop("disabled", true);
      }

      var quantityInput = $($("#generation_form").find('input[name="quantity"]')[0]);
      console.log("duo count ->");
      console.log(selection.data("duo_count"));
      if(selection.data("duo_count")==0 && quantityInput.attr("step")=="2") {
         quantityInput.attr("step", "1").attr("min", 1).val("1");
      }
      // if(selection.data("driving_type")=="gateways" && quantityInput.attr("step")=="2") {
      //   quantityInput.attr("step", "1").attr("min", 1).val("1");
      // }
      else if(selection.data("duo_count")>0 && quantityInput.attr("step")=="1") {
        quantityInput.attr("step", "2").attr("min", 2).val("2");
      }
    }).trigger("change");

    if(this.selected_session1!=null) {
      this.recomputeRemainingHours();  
    }
    
  }

  onDateClicked(data) {
    console.log(data);
    var self = this;
    PrettyAlert.clear("#add_session_event_modal");
    $("#add_session_event_modal").modal();
    $('.modal-backdrop').appendTo("#calendar_card");

    var date = null;
    if(data.date!=null) {
      date = moment(data.date).minute(0);
    }
    else {
      date = moment().minute(0);
    }
    
    if(date.hours()==0) {
      date.add(7, "hours");
    }
    $("#add_event_date").val(date.format("YYYY-MM-DDTHH:mm"));
    $("#add_event_duration").val("01:00");


    $("#add_session_event_form").unbind("submit").submit(function(e) {
      e.preventDefault();

      self.createEvent($(this).serializeJSON());

    });
  }

  onDataChanged(data) {

  }

  onDestroy() {
        
  }

  onUnsavedEventClicked(e) {
    var self = this;
    var event = e.event;
    this.selectedEvent = event;
    e.jsEvent.preventDefault();

    $("#delete_unsaved_event_btn").off("click").on("click", function(e) {
      e.preventDefault();
      self.selectedEvent.remove();
      delete self.unsaved_events[self.selectedEvent.extendedProps.uid];

      $("#edit_unsaved_event_modal").modal("hide");
      self.recomputeRemainingHours();
    });

    $("#edit_unsaved_event_session_ids").select2({
        language: "fr",
        maximumSelectionLength: 2,
        ajax: {
          url: self.base_url+'/sessions',
          quietMillis: 200,
          data: function (params) {
            params.instructor_id = self.instructor_id;
            params.date = moment(event.start).format("YYYY-MM-DDTHH:mm");
            return params;
          },
          processResults: function (data) {
            return {
              results: data
            }
          }
        }
      });

    $("#edit_unsaved_event_session_ids").val(null).trigger("change");
    $("#edit_unsaved_event_session_ids").find("option").remove();

    console.log("ici");
    console.log(self.selectedEvent);
    if(self.selectedEvent.extendedProps.event_type=="type_driving_lesson_duo") {
      $("#edit_unsaved_event_session_ids_part").show();

       $.each(self.unsaved_events[self.selectedEvent.extendedProps.uid].session_ids, function(idx, session_id) {
        var option = new Option("Session #"+session_id, session_id, true, true);
        $("#edit_unsaved_event_session_ids").append(option);
      });
    }
    else {
      $("#edit_unsaved_event_session_ids_part").hide();  
    }

    $("#update_unsaved_event_btn").off("click").on("click", function(e) {
      e.preventDefault();

      var data = $("#edit_unsaved_event_form").serializeJSON().calendar_event;

      var start = moment(data.date);
      var hours = parseInt(data.duration.split(":")[0]);
      var minutes = parseInt(data.duration.split(":")[1]);
      var end = moment(data.date).add(hours, "hours").add(minutes, "minutes");

      self.unsaved_events[self.selectedEvent.extendedProps.uid].description = data.description;
      self.unsaved_events[self.selectedEvent.extendedProps.uid].place_indication = data.place_indication;
      self.unsaved_events[self.selectedEvent.extendedProps.uid].date = data.date;
      self.unsaved_events[self.selectedEvent.extendedProps.uid].duration = data.duration;
      self.unsaved_events[self.selectedEvent.extendedProps.uid].session_ids = data.session_ids;

      self.selectedEvent.setDates(start.toDate(), end.toDate());
      self.selectedEvent.setExtendedProp("place_indication", data.place_indication);
      self.selectedEvent.setExtendedProp("description", data.description);
       $("#edit_unsaved_event_modal").modal("hide");
    });

    $("#edit_unsaved_event_modal").modal();
    $('.modal-backdrop').appendTo("#calendar_card");


    var start = moment(event.start);
    var end = moment(event.end);

    var duration = moment.duration(end.diff(start));
    var hours = duration.hours();
    var minutes = duration.minutes();

    $("#edit_unsaved_event_date").val(start.format("YYYY-MM-DDTHH:mm"));
    $("#edit_unsaved_event_duration").val(this.pad(hours,2)+":"+this.pad(minutes,2));
    $("#edit_unsaved_event_place_indication").val(event.extendedProps.place_indication);
    $("#edit_unsaved_event_description").val(event.extendedProps.description);
  }


  onEventClicked(e) {
    var self = this;
    var event = e.event;
    this.selectedEvent = event;
    e.jsEvent.preventDefault();

    console.log("Clicked on event");
    console.log(event);

     $("#update_event_btn").prop("disabled", false);
     $("#delete_session_btn").prop("disabled", false);
     $("#delete_session_event_btn").prop("disabled", false);


      $("#edit_event_session_ids").select2({
        language: "fr",
        maximumSelectionLength: 2,
        ajax: {
          url: self.base_url+'/sessions',
          quietMillis: 200,
          data: function (params) {
            params.instructor_id = self.instructor_id;
            params.date = moment(event.start).format("YYYY-MM-DDTHH:mm");
            return params;
          },
          processResults: function (data) {
            return {
              results: data
            }
          }
        }
      });


      $("#edit_event_session_ids").val(null).trigger("change");
      $("#edit_event_session_ids").find("option").remove();

      $.each(event.extendedProps.sessionIds, function(idx, session_id) {
        var option = new Option("Session #"+session_id, session_id, true, true);
        $("#edit_event_session_ids").append(option);
      });



    if(event.extendedProps.is_editable && event.extendedProps.sessionIds.length==1) {
      $("#delete_session_btn").removeClass("d-none").unbind("click").click(function(e) {
        e.preventDefault();
        $("#edit_event_modal").modal("hide");
        self.destroySession(self.selectedEvent.extendedProps.sessionIds[0], function(json) {
          console.log("Session destroyed");
          self.calendar.refetchEvents();
        });
      });
    }
    else {
      $("#delete_session_btn").addClass("d-none").unbind("click");
    }


    if(event.extendedProps.is_deletable) {
      $("#delete_session_event_btn").removeClass("d-none").unbind("click").click(function(e) {
        e.preventDefault();
        $("#edit_event_modal").modal("hide");
        self.destroyEvent(event.id, function(json) {
          if(json.success) {
            self.calendar.getEventById(event.id).remove();
          }
        });
      });
    }
    else {
      $("#delete_session_event_btn").addClass("d-none").unbind("click");
    }

    $("#update_session_event_form").unbind("submit").submit(function(e) {
      e.preventDefault();

      $("#update_event_btn").prop("disabled", true);
      $("#delete_session_btn").prop("disabled", true);
      $("#delete_session_event_btn").prop("disabled", true);

      var jsonData = $(this).serializeJSON(); 
      jsonData.no_affectation = true;

      var ajaxData = {
          url: self.base_url+'/'+event.id,
          data : jsonData,
          method: "PATCH"
      };

      $.ajax(ajaxData).done(function(json) {
        $("#update_event_btn").prop("disabled", false);
        $("#delete_session_btn").prop("disabled", false);
        $("#delete_session_event_btn").prop("disabled", false);
        if(json.success) {
          PrettyAlert.clear("#update_session_event_form");
          $("#edit_event_modal").modal("hide");
          self.updateEventCalendar(json.event);
        }
        else {
          PrettyAlert.showError({ selector: "#update_session_event_form", message: "Une erreur est survenue, veuillez réessayer." });
        }
      }).fail(function(err) {
        PrettyAlert.showError({ selector: "#update_session_event_form", message: "Une erreur est survenue, veuillez réessayer." });
        $("#update_event_btn").prop("disabled", false);
        $("#delete_session_btn").prop("disabled", false);
        $("#delete_session_event_btn").prop("disabled", false);
      });

    });


    var start = moment(event.start);
    var end = moment(event.end);

    var duration = moment.duration(end.diff(start));
    var hours = duration.hours();
    var minutes = duration.minutes();

    $("#edit_event_date").val(start.format("YYYY-MM-DDTHH:mm"));
    if(window.application.context=="bo") {
      $("#edit_event_duration").val(this.pad(hours,2)+":"+this.pad(minutes,2));
    }
    else {
      $("#edit_event_duration").html(this.pad(hours,2)+":"+this.pad(minutes,2)); 
    }
    $("#edit_event_place_indication").val(event.extendedProps.place_indication);
    $("#edit_event_description").val(event.extendedProps.description);

    $("#edit_event_modal").modal();
    $('.modal-backdrop').appendTo("#calendar_card");
  }

  createEvent(data) {
    var url = $("#add_session_event_form").attr("action");
    $("#add_to_planning_btn").prop("disabled", true);
    var ajaxData = {
        url: url,
        data : data,
        method: "POST"
    };

    var self = this;
    $.ajax(ajaxData).done(function(json) {
      $("#add_session_event_save_btn").prop("disabled", false);
      if(json.success) {
        PrettyAlert.clear("#add_session_event_form");
        $("#add_event_modal").modal("hide");
        self.addEventCalendar(json.event);
      }
      else {
        PrettyAlert.showError({ selector: "#add_session_event_form", message: "Une erreur est survenue, veuillez réessayer." });
      }
    }).fail(function(err) {
      PrettyAlert.showError({ selector: "#add_session_event_form", message: "Une erreur est survenue, veuillez réessayer." });
      $("#add_session_event_save_btn").prop("disabled", false);
    });
  }

  destroySession(sessionId, callback) {
    var url = this.base_url+"/"+this.instructor_id+"/session/"+sessionId;

    var ajaxData = {
        url: url,
        data : {
          noredirect: 1,
        },
        method: "DELETE"
    };
    $.ajax(ajaxData).done(callback);
  }

  destroyEvent(eventId, callback) {
    var url = this.base_url+"/"+eventId;

    var ajaxData = {
        url: url,
        data : {},
        method: "DELETE"
    };
    $.ajax(ajaxData).done(callback);
  }

  addEventCalendar(event) {
    var cEvent = this.calendar.addEvent(event, true);
  }

  updateEventCalendar(event) {
    var cEvent = this.calendar.getEventById(event.id);
    cEvent.setDates(event.start, event.end);
    cEvent.setProp("color", event.color);
    cEvent.setProp("textColor", event.textColor);
    cEvent.setProp("title", event.title);
    cEvent.setResources([event.resourceId]);
    cEvent.setExtendedProp("customerIds", event.customerIds);
    cEvent.setExtendedProp("customers", event.customers);
    cEvent.setExtendedProp("event_type", event.event_type);
    cEvent.setExtendedProp("place_indication", event.place_indication);
    cEvent.setExtendedProp("description", event.description);

    cEvent.setExtendedProp("sessionIds", event.sessionIds);
    cEvent.setExtendedProp("affectedSessionIds", event.affectedSessionIds);
  }

  clearUnsavedEvents() {
    var clearableEvents = [];
    $.each(this.calendar.getEvents(), function(idx, event) {
      if(event.id=="") {
        clearableEvents.push(event);
      }
    });

    for(var i=0; i<clearableEvents.length;i++) {
      clearableEvents[i].remove();
    }

    this.unsaved_events = {};

    $(".fc-validate_entries-button").attr("disabled", true);
    $(".fc-fill_voids-button").attr("disabled", false);
    $(".fc-add_manually-button").attr("disabled", false);
  }

  updateEvent(id, data) {
    $("#update_event_btn").prop("disabled", true);

    var ajaxData = {
        url: this.base_url+'/'+id,
        data : data,
        method: "PATCH"
    };

    var self = this;
    $.ajax(ajaxData).done(function(json) {
      if(json.success) {
        self.updateEventCalendar(json.event);
      }
    }).fail(function(err) {
    });
  }

  pad(n, width, _z) {
    var z = _z || '0';
    n = n + '';
    return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
  }

  recomputeRemainingHours() {
    console.log("recomputeRemainingHours -> "+this.total_hours);
    var _session1_duration = 0
    var _session2_duration = 0

    this.remaining_hours_session1 = 999999;
    this.remaining_hours_session2 = (this.selected_session2!=null ? 999999 : 0);
    this.remaining_duo_count = this.duo_count;

    this.max_hours_solo = (this.total_hours - (this.duo_count*4));

    this.hours_solo1 = 0;
    this.hours_solo2 = 0;

    var self = this;

    if(this.total_hours>0){
      var duration = 0;
      $.each(this.unsaved_events, function(idx, e) {
        if(e.event_type=="type_driving_lesson" || e.event_type=="type_driving_lesson_duo") {
          var hours = parseInt(e.duration.split(":")[0]);
          var minutes = parseInt(e.duration.split(":")[1]);

          if(self.selected_session1!=null && e.session_ids.includes(self.selected_session1.id)) {
             _session1_duration += hours;
             if(e.event_type=="type_driving_lesson") {
              self.hours_solo1 += hours;
            }
          }
         
         if(self.selected_session2!=null && e.session_ids.includes(self.selected_session2.id)) {
             _session2_duration += hours;
             if(e.event_type=="type_driving_lesson") {
              self.hours_solo2 += hours;
            }
          }
         
        }

        if(e.event_type=="type_driving_lesson_duo" && e.session_ids.includes(self.selected_session1.id) && self.selected_session2!=null && e.session_ids.includes(self.selected_session2.id)) {
          self.remaining_duo_count -= 1;
        }
      });

      this.remaining_hours_session1 = this.base_remaining_hours-_session1_duration;


      $("#manual_session_hours_"+this.selected_session1.id).html(""+(this.max_hours_solo - this.hours_solo1));
      if(this.selected_session2!=null) {
       
        this.remaining_hours_session2 = this.base_remaining_hours-_session2_duration;
        $("#manual_session_hours_"+this.selected_session2.id).html(""+(this.max_hours_solo - this.hours_solo2));

        if(this.remaining_hours_session1!=0 || this.remaining_hours_session2!=0 || this.remaining_duo_count>0) {
          $(".fc-validate_manual_session-button").prop("disabled", true);
        }
        else {
          $(".fc-validate_manual_session-button").prop("disabled", false);
        }
      }
      else {
        if(this.remaining_hours_session1!=0 || this.remaining_duo_count>0) {
          $(".fc-validate_manual_session-button").prop("disabled", true);
        }
        else {
          $(".fc-validate_manual_session-button").prop("disabled", false);
        }
      }

      // console.log("remaining_hours_session1 -> "+this.remaining_hours_session1);
      // console.log("remaining_hours_session2 -> "+this.remaining_hours_session2);
      // console.log("(this.duo_count*4) -> "+(this.duo_count*4));
      // console.log("remaining_duo_count -> "+this.remaining_duo_count);

      $("#card_session_"+this.selected_session1.id).find('.fc-event').removeClass("disabled");
      if(this.selected_session2!=null) {
        $("#card_session_"+this.selected_session2.id).find('.fc-event').removeClass("disabled");
      }

      if(this.duo_count>0 && this.remaining_duo_count==0) {
        $("#card_session_common").addClass("disabled-session-card");
      }
      else if(this.duo_count>0 && this.remaining_duo_count>0 && this.remaining_hours_session1<=(this.duo_count*4) && this.remaining_hours_session2<=(this.duo_count*4)) {
        $("#card_session_common").removeClass("disabled-session-card");
        $("#card_session_"+this.selected_session1.id).addClass("disabled-session-card");
        $("#card_session_"+this.selected_session2.id).addClass("disabled-session-card");
      }
       else if(this.duo_count>0 && this.remaining_duo_count>0 && (this.remaining_hours_session1>(this.duo_count*4) || this.remaining_hours_session2>(this.duo_count*4))) {
        $("#card_session_common").addClass("disabled-session-card");
      }

      if(this.remaining_hours_session1<=0 || (this.remaining_hours_session1<=(this.duo_count*4) && (this.max_hours_solo - this.hours_solo1)==0)) {
        $("#card_session_"+this.selected_session1.id).addClass("disabled-session-card");
      }
      else {
        $("#card_session_"+this.selected_session1.id).removeClass("disabled-session-card"); 
        var _remainingSolo = (this.max_hours_solo - this.hours_solo1);
        if(_remainingSolo<3) {
          $("#card_session_"+this.selected_session1.id).find('.event_3hour').addClass("disabled");
        }
        else {
          $("#card_session_"+this.selected_session1.id).find('.event_3hour').removeClass("disabled");
        }
        
        if(_remainingSolo<2) {
          $("#card_session_"+this.selected_session1.id).find('.event_2hour').addClass("disabled");
        }
        else {
          $("#card_session_"+this.selected_session1.id).find('.event_2hour').removeClass("disabled");
        }

        if(_remainingSolo<1) {
          $("#card_session_"+this.selected_session1.id).find('.event_1hour').addClass("disabled");
        }
        else {
          $("#card_session_"+this.selected_session1.id).find('.event_1hour').removeClass("disabled");
        }
      }

      if(self.selected_session2!=null) {
        if(this.remaining_hours_session2<=0 || (this.remaining_hours_session2<=(this.duo_count*4) && (this.max_hours_solo - this.hours_solo2)==0)) {
          $("#card_session_"+this.selected_session2.id).addClass("disabled-session-card");
        }
        else {
          if(this.remaining_hours_session1<=(this.duo_count*4)) {
            $("#card_session_"+this.selected_session2.id).removeClass("disabled-session-card"); 
          }
          else {
             $("#card_session_"+this.selected_session2.id).addClass("disabled-session-card"); 
          }

          var _remainingSolo = (this.max_hours_solo - this.hours_solo2);
          if(_remainingSolo<3) {
            $("#card_session_"+this.selected_session2.id).find('.event_3hour').addClass("disabled");
          }
          else {
            $("#card_session_"+this.selected_session2.id).find('.event_3hour').removeClass("disabled");
          }
          
          if(_remainingSolo<2) {
            $("#card_session_"+this.selected_session2.id).find('.event_2hour').addClass("disabled");
          }
          else {
            $("#card_session_"+this.selected_session2.id).find('.event_2hour').removeClass("disabled");
          }

          if(_remainingSolo<1) {
            $("#card_session_"+this.selected_session2.id).find('.event_1hour').addClass("disabled");
          }
          else {
            $("#card_session_"+this.selected_session2.id).find('.event_1hour').removeClass("disabled");
          }
        }
      }

    }
  }

}
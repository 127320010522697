import { Calendar } from '@fullcalendar/core';
import frenchTranslation from '@fullcalendar/core/locales/fr';
import bootstrapPlugin from '@fullcalendar/bootstrap';
import interactionPlugin from '@fullcalendar/interaction';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import scrollgridPlugin from '@fullcalendar/scrollgrid';

import resourceTimeGrid from '@fullcalendar/resource-timegrid';
import resourceTimeline from '@fullcalendar/resource-timeline';

export default class ClientsEdit {

    constructor() {
        this.entity_id = null;
        this.dropify = null;
        this.validation = null;
        this.wizard = null;
        this.admin_select = null;
        this.source_select = null;
        this.city_select = null;
        this.formula_select = null;
        this.phone_mask = null;

        this.init();
        this.initPlanning();
        this.bindEvents();
        //window.application.setOnDataChangeListener(this);
    }

    init(){
        var self = this;
        this.dropify = $('.client-dropify').dropify({
            messages: {
                'default': I18n.t('plugins.dropify.messages.default'),
                'replace': I18n.t('plugins.dropify.messages.replace'),
                'remove':  I18n.t('plugins.dropify.messages.remove'),
                'error':   I18n.t('plugins.dropify.messages.error')
            },
            error: {
                'fileSize': I18n.t('plugins.dropify.error.fileSize'),
                'minWidth': I18n.t('plugins.dropify.error.minWidth'),
                'maxWidth': I18n.t('plugins.dropify.error.maxWidth'),
                'minHeight': I18n.t('plugins.dropify.error.minHeight'),
                'maxHeight': I18n.t('plugins.dropify.error.maxHeight'),
                'imageFormat': I18n.t('plugins.dropify.error.imageFormat'),
                'fileExtension': I18n.t('plugins.dropify.error.fileExtension')
            }
        });

        this.phone_mask = $("#customer_phone").mask('00 00 00 00 00');


        $.validator.addMethod("phone_number_fr", function( value, element ) {
            let val = value.replace(/ /g, '');
            return val.match(/[0-9]/g) && value.replace(/ /g, '').length === 10;
        }, I18n.t('common.js_validation.phone_number_fr'));

        let validation_options = {
            rules: {
                "customer[last_name]":{
                    required: true
                },
                "customer[first_name]":{
                    required: true
                },
                "customer[phone]":{
                    required: true,
                    phone_number_fr: true
                },
                "customer[email]":{
                    required: true,
                    email: true
                }
            },
            errorElement : 'div',
            errorLabelContainer: '.errorTxt',
            ignore: [],
            focusInvalid: false,
            invalidHandler: function(form, validator) {

                if (!validator.numberOfInvalids())
                    return;


                $('html, body').animate({
                    scrollTop: $(validator.errorList[0].element).parent().offset().top - 100
                }, 1000);

            }
        }

        if(I18n.locale !== 'en'){
            validation_options.messages = require("jquery-validation/dist/localization/messages_"+I18n.locale);
        }

        this.validation = $("#customer_form").validate(validation_options);


        this.admin_select = $('#customer_admin_id').selectpicker();
        this.source_select = $('#customer_source_type').selectpicker();
        this.city_select = $('#customer_city_id').selectpicker();
        this.formula_select = $('#customer_formula_id').selectpicker();


        $('a[href="#sessions_planning"]').on('shown.bs.tab', function (e) {
          console.log("sessions_planning");
          self.calendar.render();
        });
    }


    initPlanning() {
        var self = this;
        this.entity_id = $("#client_planning").data("entity_id");
        this.planning_initial_date = $("#client_planning").data("initial_date");
        this.customer_id = $("#client_planning").data("customer_id");
        this.driving_instructor_url = $("#client_planning").data("driving_instructor_url");
        this.calendar = new Calendar(document.getElementById('client_planning'), {
          schedulerLicenseKey: 'CC-Attribution-NonCommercial-NoDerivatives',
          themeSystem: "bootstrap",
          locale: frenchTranslation,
          initialView: "dayGridMonth",
          initialDate: (this.planning_initial_date==null ? new Date() : this.planning_initial_date),
          slotDuration: "00:15:00",
          slotMinTime: "06:00:00",
          slotMaxTime: "21:00:00",
          dayMaxEvents: true,
          dayMinWidth: 200,
          allDaySlot: false,
          editable: true,
          selectable: true,
          eventDisplay: "block",
          height: 1000,
          eventStartEditable: false,
          plugins: [ bootstrapPlugin, interactionPlugin, scrollgridPlugin, dayGridPlugin, listPlugin ],
          headerToolbar: { 
            left: this.driving_instructor_url!=null ? "prev,next di_btn" : "prev,next", 
            center: "title",
            right: "dayGridMonth,listMonth"
          },
          bootstrapFontAwesome: {
              di_btn: "fa-calendar-alt"
            },
            customButtons: {
              di_btn: {
                click: function(){
                  Turbolinks.visit(self.driving_instructor_url);
                }
              }
            },

          events: {
            url: '/planning/'+this.entity_id+'/events',
            method: 'GET',
            extraParams: {
              customer_id: self.customer_id,
            },
            failure: function() {
              console.log('there was an error while fetching events!');
            },
          },
          eventSourceSuccess: function(content, xhr) {
            console.log("eventSourceSuccess -> "+content.events.length);
            return content.events;
          },
          resources: {
            url: '/planning/'+this.entity_id+'/driving_instructors',
            method: 'GET',
            success: function(raw_resources, xhr) {

            }
          },
          eventClick: function(info) { self.onEventClicked(info) },
          eventContent: function(arg) {
            console.log(arg);
            let rootElement = document.createElement("div")
            $(rootElement).css("background-color", arg.event.color);
            $(rootElement).css("color", arg.event.textColor);

            let title = document.createElement("div")
            let customerName = document.createElement("div")
            let icon = document.createElement('i')

            icon.className = 'ri-user-fill';
            title.appendChild(icon);

            if (arg.event.extendedProps.customerIds.length > 1) {
              let icon2 = document.createElement('i')
              icon2.className = 'ri-user-fill';
              title.appendChild(icon2);
            }

            title.innerHTML += " "+arg.event.title;

            customerName.innerHTML = self.getEventCustomerNames(arg.event).join(" et ");

            let arrayOfDomNodes = [ title, customerName ]
            return { domNodes: arrayOfDomNodes }
          }
        });

        this.calendar.render();
    }

    getEventCustomerNames(event) {
    var names = [];

    $.each(event.extendedProps.customers, function(idx, customer) {
      names.push(customer.text);
    });

    return names;
   }

   onEventClicked(e) {
      var self = this;
      var event = e.event;
      console.log(event);
      this.selectedEvent = event;
      e.jsEvent.preventDefault();

      PrettyAlert.clear("#edit_event_form");

      $("#update_event_btn").hide();

      $("#edit_event_form").unbind("submit").submit(function(e) {
        e.preventDefault();

        //self.updateEvent(event.id, $(this).serializeJSON());

      });

      $("#delete_event_btn").unbind("click").click(function(e) {
        e.preventDefault();

        $("#edit_event_modal").modal("hide");
        self.deleteEvent(event.id);
      });

      $("#edit_event_modal").modal();

      $("#edit_event_driving_instructor_id").html("");

      /*$.each(this.calendar.currentData.resourceStore, function(idx, resource) {
        $("<option>").attr("value", resource.id).html(resource.title).appendTo("#edit_event_driving_instructor_id");
      });*/

      $("<option>").attr("value", event.extendedProps.resourceId).html(event.extendedProps.resource_name).appendTo("#edit_event_driving_instructor_id");

      $("#edit_event_place_indication").val(event.extendedProps.place_indication);
      $("#edit_event_description").val(event.extendedProps.description);
      $("#edit_event_driving_instructor_id").val(event.extendedProps.resourceId).trigger("change");

      $("#edit_event_customer_ids").val(null).trigger("change");

      $.each(event.extendedProps.customers, function(idx, customer) {
        var option = new Option(customer.text, customer.id, true, true);
        $("#edit_event_customer_ids").append(option);
      });

      $("#edit_event_customer_ids").trigger({
        type: 'select2:select',
        params: {
          data: event.extendedProps.customers
        }
      }).trigger('change');

      var start = moment(event.start);
      var end = moment(event.end);

      var duration = moment.duration(end.diff(start));
      var hours = duration.hours();
      var minutes = duration.minutes();

      $("#edit_event_event_type").val(event.extendedProps.event_type);

      $("#edit_event_date").val(start.format("YYYY-MM-DDTHH:mm"));
      $("#edit_event_duration").val(this.pad(hours,2)+":"+this.pad(minutes,2));

    }

    updateEvent(id, data) {
      $("#update_event_btn").prop("disabled", true);

      var ajaxData = {
          url: "/planning/"+this.entity_id+"/"+id,
          data : data,
          method: "PATCH"
      };

      var self = this;
      $.ajax(ajaxData).done(function(json) {
        $("#update_event_btn").prop("disabled", false);
        if(json.success) {
          PrettyAlert.clear("#edit_event_form");
          $("#edit_event_modal").modal("hide");
          self.updateEventCalendar(json.event);
        }
        else {
          PrettyAlert.showError({ selector: "#edit_event_form", message: "Une erreur est survenue, veuillez réessayer." });
        }
      }).fail(function(err) {
        PrettyAlert.showError({ selector: "#edit_event_form", message: "Une erreur est survenue, veuillez réessayer." });
        $("#update_event_btn").prop("disabled", false);
      });
    }


    bindEvents() {

      $("#edit_event_driving_instructor_id").select2({
          language: "fr",
          minimumInputLength: 2,
          ajax: {
              url: '/planning/'+this.entity_id+'/driving_instructors',
              quietMillis: 200,
              processResults: function (data) {
                return {
                  results: data
                }
              }
          }
        });

      $("#edit_event_customer_ids").select2({
          language: "fr",
          maximumSelectionLength: 2,
          minimumInputLength: 2,
          ajax: {
              url: '/planning/customers',
              quietMillis: 200,
              processResults: function (data) {
                return {
                  results: data
                }
              }
          }
      });

      $("#business_notification_call_subject").on("change", function() {
        if($(this).val() === "other") {
            $(".description-part").removeClass("d-none");
            $("#description").val("");
        } else {
            $(".description-part").addClass("d-none");
        }
      }).trigger("change");

      $(".open-alert-comment-modal").off("click").on("click", function(e) {
        $("#modal-alert-comment").modal();
        $("#modal-alert-comment .modal-title").html($(this).data("title"));
        $("#modal-alert-comment .modal-body").html($(this).data("content").split("\n").join("<br/>"));
      });
    }

    updateEvent(id, data) {
      $("#update_event_btn").prop("disabled", true);

      var ajaxData = {
          url: "/planning/"+this.entity_id+"/"+id,
          data : data,
          method: "PATCH"
      };

      var self = this;
      $.ajax(ajaxData).done(function(json) {
        $("#update_event_btn").prop("disabled", false);
        if(json.success) {
          PrettyAlert.clear("#edit_event_form");
          $("#edit_event_modal").modal("hide");
          self.updateEventCalendar(json.event);
        }
        else {
          PrettyAlert.showError({ selector: "#edit_event_form", message: "Une erreur est survenue, veuillez réessayer." });
        }
      }).fail(function(err) {
        PrettyAlert.showError({ selector: "#edit_event_form", message: "Une erreur est survenue, veuillez réessayer." });
        $("#update_event_btn").prop("disabled", false);
      });
    }

    updateEventCalendar(event) {
      var cEvent = this.calendar.getEventById(event.id);
      cEvent.setDates(event.start, event.end);
      cEvent.setProp("color", event.color);
      cEvent.setProp("textColor", event.textColor);
      cEvent.setProp("title", event.title);
      cEvent.setResources([event.resourceId]);
      cEvent.setExtendedProp("resourceId", event.resourceId);
      cEvent.setExtendedProp("resource_name", event.resource_name);
      cEvent.setExtendedProp("customerIds", event.customerIds);
      cEvent.setExtendedProp("customers", event.customers);
      cEvent.setExtendedProp("event_type", event.event_type);
      cEvent.setExtendedProp("place_indication", event.place_indication);
      cEvent.setExtendedProp("description", event.description);
    }

    onDataChanged(data) {
    }

    onDestroy() {
        if(this.validation !== undefined){
            this.validation.destroy();
        }
        this.admin_select.selectpicker('destroy');
        this.source_select.selectpicker('destroy');
        this.city_select.selectpicker('destroy');
        this.formula_select.selectpicker('destroy');
        this.dropify.data('dropify').destroy();
        this.phone_mask.unmask();
    }

    pad(n, width, _z) {
        var z = _z || '0';
        n = n + '';
        return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
      }

}
export default class DrivingCoursesNotAffected {
    constructor() {
        this.bindEvents();
    }
  
  
    bindEvents() {
      var self = this;

        $(".open-driving-test-prebook-date-modal").off("click").on("click", function(e) {
            e.preventDefault();

            var actionURL = $(this).data("action_url");

            $("#update_driving_test_prebook_date_form").attr("action", actionURL);
            $("#session_driving_test_prebooked_at").val($(this).data("prebook_date"));
            $("#update_driving_test_prebook_date_modal").modal();
        });

        $(".open-unaffected-comment-modal").off("click").on("click", function(e) {
            e.preventDefault();

            var actionURL = $(this).data("action_url");

            $("#session_comment_form").attr("action", actionURL);
            $("#session_comment").val($(this).data("content"));
            $("#session_comment_modal").modal();
        });
    }

}